import React from "react";
import dropdownStyles from "../Dropdown.module.css";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./DaySelectorDatePicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const DaySelectorDatePicker = ({ onChange, selectedDate }) => {
  const { t } = useTranslation();
  const date = new Date(`${selectedDate}T00:00`);
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className={dropdownStyles.dropDown} onClick={onClick} ref={ref}>
      <FontAwesomeIcon
        icon={faCalendar}
        className={dropdownStyles.dropDownIcon}
      />
      <div className={dropdownStyles.dropDownValue}>
        {t("calendar.fullDate", { date: new Date(value) })}
      </div>
      <FontAwesomeIcon
        icon={faChevronDown}
        size="sm"
        className={dropdownStyles.dropDownChevron}
      />
    </div>
  ));

  return (
    <div className="teeTapp__datepicker__wrapper">
      <DatePicker
        selected={date}
        onChange={(date) => {
          onChange(format(date, "yyyy-MM-dd"));
        }}
        customInput={<CustomInput />}
        calendarClassName="teeTapp__datepicker"
      />
    </div>
  );
};

export default DaySelectorDatePicker;

import React from "react";
import Dropdown from "../Dropdown";
import { useTranslation } from "react-i18next";
import { getActiveDays } from "../../utils/activeWeek.js";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DaySelectorDropdown = ({ onOptionChange, selectedDate }) => {
  const { t } = useTranslation();
  const activeDays = getActiveDays();
  const options = activeDays.map((day) => ({
    value: day.date,
    label: t("calendar.fullDate", { date: day.fullDate })
  }));

  return (
    <Dropdown
      options={options}
      onChange={onOptionChange}
      active={selectedDate}
      icon={faCalendar}
    />
  );
};

export default DaySelectorDropdown;

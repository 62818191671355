import React from "react";
import { client } from "../utils/api-client";
import styles from "./ResourceCheckboxes.module.css";
import Checkbox from "./Checkbox";

const ResourceCheckboxes = ({ onUpdate, className }) => {
  const [resources, setResources] = React.useState([]);
  const [resourcesFetched, setResourcesFetched] = React.useState(false);

  React.useEffect(() => {
    if (resourcesFetched) return;

    client("resources.json").then((responseData) => {
      setResources(
        responseData.map((resource) => ({
          id: resource.id,
          name: resource.name,
          checked: true,
        }))
      );
    });

    setResourcesFetched(true);
  }, [resourcesFetched]);

  React.useEffect(() => {
    onUpdate(resources);
  }, [resources, onUpdate]);

  const onResourceChange = (id, value) => {
    const resource = resources.find((resource) => resource.id === id);
    const resourceIndex = resources.findIndex((resource) => resource.id === id);
    resource.checked = value;
    resources[resourceIndex] = resource;
    setResources([...resources]);
  };

  return (
    <div className={`${styles.checkboxes} ${className}`}>
      {resources.map((resource, index) => (
        <Checkbox
          checked={resource.checked}
          key={resource.id}
          onClick={(value) => onResourceChange(resource.id, value)}
          className={styles.checkbox}
          color={`color${index + 1}`}
        >
          {resource.name}
        </Checkbox>
      ))}
    </div>
  );
};

export default ResourceCheckboxes;

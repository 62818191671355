import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../context/user-context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faCalendarDays,
  faCalendarPlus,
  faUser,
  faReceipt,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Nav.module.css";

const Nav = ({ isOpen, onItemClick }) => {
  const { t } = useTranslation();
  const menuClasses = classnames(styles.menu, {
    [styles.open]: isOpen,
  });
  const { user } = React.useContext(UserContext);
  const isAdmin =
    user.type === "resource_admin" ||
    user.type === "club_admin" ||
    user.type === "admin";
  const isPlayerOrAdmin = user.type == "player" || isAdmin;

  return (
    <nav className={menuClasses}>
      {isPlayerOrAdmin && (
        <NavLink to="/" onClick={onItemClick}>
          <span className={styles.menuIconWrapper}>
            <FontAwesomeIcon className={styles.menuItemIcon} icon={faHouse} />
          </span>
          <span className={styles.menuItemText}>{t("nav.home")}</span>
        </NavLink>
      )}
      {isPlayerOrAdmin && (
        <NavLink to="/reservations" onClick={onItemClick}>
          <span className={styles.menuIconWrapper}>
            <FontAwesomeIcon
              className={styles.menuItemIcon}
              icon={faCalendarPlus}
            />
          </span>
          <span className={styles.menuItemText}>{t("nav.book")}</span>
        </NavLink>
      )}

      <NavLink to="/calendar" onClick={onItemClick}>
        <span className={styles.menuIconWrapper}>
          <FontAwesomeIcon
            className={styles.menuItemIcon}
            icon={faCalendarDays}
          />
        </span>
        <span className={styles.menuItemText}>{t("nav.calendar")}</span>
      </NavLink>
      {isPlayerOrAdmin && (
        <NavLink to="/my_reservations" onClick={onItemClick}>
          <span className={styles.menuIconWrapper}>
            <FontAwesomeIcon className={styles.menuItemIcon} icon={faUser} />
          </span>
          <span className={styles.menuItemText}>{t("nav.myReservations")}</span>
        </NavLink>
      )}
      <NavLink to="/rules" onClick={onItemClick}>
        <span className={styles.menuIconWrapper}>
          <FontAwesomeIcon className={styles.menuItemIcon} icon={faReceipt} />
        </span>
        <span className={styles.menuItemText}>{t("nav.rules")}</span>
      </NavLink>
      {isAdmin && (
        <NavLink to="/admin" onClick={onItemClick}>
          <span className={styles.menuIconWrapper}>
            <FontAwesomeIcon className={styles.menuItemIcon} icon={faCog} />
          </span>
          <span className={styles.menuItemText}>{t("nav.admin")}</span>
        </NavLink>
      )}
    </nav>
  );
};

const NavLink = (props) => {
  const location = useLocation();
  const activeRoute = location.pathname;
  const navLinkClasses = classnames(styles.menuItem, {
    [styles.active]: activeRoute.split("/")[1] === props.to.split("/")[1],
  });
  return (
    <Link className={navLinkClasses} {...props}>
      {props.children}
    </Link>
  );
};

export default Nav;

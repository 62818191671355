import React from "react";
import styles from "./Profile.module.css";
import Avatar from "../components/Avatar.js";
import Button from "../components/Button.js";
import { RadioButton, RadioButtons } from "../components/RadioButton";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";
import { Link } from "react-router-dom";
import LinkButton from "../components/LinkButton";
import { client } from "../utils/api-client";

const Profile = ({ onLogout }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState(i18next.language);
  const { user, setUser } = React.useContext(UserContext);
  const [displayName, setDisplayName] = React.useState(user.display_name);
  const [updatingName, setUpdatingName] = React.useState(false);

  const onLanguageChange = (language) => {
    i18next.changeLanguage(language);
    setLanguage(language);
  };

  function updateName() {
    if (user.id) {
      client(`users/${user.id}.json`, {
        method: "PUT",
        body: JSON.stringify({
          display_name: displayName
        })
      }).then((user) => {
        setUser(user);
        setUpdatingName(false);
      });
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.overview}>
        <Avatar
          initial={user.display_name.charAt(0)}
          className={styles.avatar}
          size="lg"
        />
        {updatingName ? (
          <div>
            <input
              type="text"
              value={displayName}
              onChange={(ev) => setDisplayName(ev.target.value)}
              className={styles.input}
            />
            <div className={styles.editButtons}>
              <Button
                onClick={() => {
                  setDisplayName(user.display_name);
                  setUpdatingName(false);
                }}
                className={styles.button}
                secondary
              >
                {t("profile.cancel")}
              </Button>
              <Button onClick={updateName} className={styles.button}>
                {t("profile.save")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <h3 className={styles.h3}>{`${user.display_name}`}</h3>
            <LinkButton onClick={() => setUpdatingName(true)}>
              {t("profile.editName")}
            </LinkButton>
          </>
        )}
      </div>
      <form className={styles.form}>
        <label className={styles.label}>{t("profile.email")}</label>
        <input
          className={styles.field}
          type="text"
          value={user.email}
          disabled={true}
          name="email"
        />
        <div className={styles.passwordWrapper}>
          <label className={styles.label}>{t("profile.password")}</label>
          <input
            className={styles.field}
            type="password"
            value="password"
            disabled={true}
            name="password"
          />
          <Link to="/change_password" className={styles.changePasswordLink}>
            <LinkButton>{t("profile.changePassword")}</LinkButton>
          </Link>
        </div>
        {user.membership && (
          <>
            <label className={styles.label}>{t("profile.membership")}</label>
            <input
              className={styles.field}
              type="input"
              value={user.membership}
              disabled={true}
              name="dob"
            />
          </>
        )}
        {user.birthday && (
          <>
            <label className={styles.label}>{t("profile.dob")}</label>
            <input
              className={styles.field}
              type="input"
              value={t("profile.dateFormat", {
                date: new Date(`${user.birthday}T00:00:00`)
              })}
              disabled={true}
              name="dob"
            />
          </>
        )}
        <label className={styles.label}>{t("profile.language")}</label>
        <RadioButtons className={styles.languages}>
          <RadioButton
            key={"es"}
            selected={language === "es"}
            onClick={() => onLanguageChange("es")}
          >
            ES
          </RadioButton>
          <RadioButton
            key={"en"}
            selected={language === "en"}
            onClick={() => onLanguageChange("en")}
          >
            EN
          </RadioButton>
        </RadioButtons>
      </form>
      <Button onClick={onLogout} block>
        {t("profile.logout")}
      </Button>
    </div>
  );
};

export default Profile;

import React, { useState } from "react";
import { getActiveWeeks, getActiveWeekIndex } from "../../utils/activeWeek.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import styles from "./DaySelector.module.css";
const classnames = require("classnames");

const Day = ({ weekDay, day, selected, disabled, onClick }) => {
  const containerClasses = classnames(styles.dayContainer, {
    [styles.daySelected]: selected,
    [styles.dayDisabled]: disabled
  });

  return (
    <div className={containerClasses} onClick={onClick}>
      <div className={styles.dayWeekDay}>{weekDay}</div>
      <div className={styles.dayDay}>{day}</div>
    </div>
  );
};

const DaySelector = ({
  children,
  date = new Date(),
  onDayChange = () => {},
  selectedDate = null
}) => {
  const childrenWrapper = children ? (
    <div className={styles.childrenWrapper}>{children}</div>
  ) : null;

  const availableWeeks = getActiveWeeks(date);
  const activeWeekIndex = getActiveWeekIndex(availableWeeks, selectedDate);
  const [activeWeek, setActiveWeek] = useState(activeWeekIndex);
  const [activeDay, setActiveDay] = useState(selectedDate);

  const prevWeekButton = () => {
    if (activeWeek !== 0) {
      return (
        <button
          className={styles.buttonIcon}
          onClick={() => setActiveWeek(activeWeek - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      );
    }
    return <div className={styles.emptyButtonIcon} />;
  };

  const nextWeekButton = () => {
    if (availableWeeks.length > 1 && activeWeek !== availableWeeks.length - 1) {
      return (
        <button
          className={styles.buttonIcon}
          onClick={() => setActiveWeek(activeWeek + 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      );
    }
    return <div className={styles.emptyButtonIcon} />;
  };

  return (
    <div className={styles.daySelectorWrapper}>
      <div className={styles.monthSelectorWrapper}>
        {prevWeekButton()}
        <div className={styles.monthSelectorMonth}>
          {availableWeeks[activeWeek].month}
        </div>
        {nextWeekButton()}
      </div>
      <div className={styles.daysWrapper}>
        {availableWeeks[activeWeek].days.map(
          ({ weekDay, day, date, disabled }) => (
            <Day
              key={date}
              weekDay={weekDay}
              day={day}
              disabled={disabled}
              selected={activeDay === date}
              onClick={() => {
                if (disabled) return;
                setActiveDay(date);
                onDayChange(date);
              }}
            />
          )
        )}
      </div>
      {childrenWrapper}
    </div>
  );
};

export default DaySelector;

import React from "react";
import styles from "./LinkButton.module.css";

const LinkButton = ({ onClick, children, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} ${styles.linkButton}`}
    >
      {children}
    </button>
  );
};

export default LinkButton;

import React from "react";
import styles from "./RadioButton.module.css";
import classnames from "classnames";

const RadioButton = ({ selected, onClick, children, className, color }) => {
  const classes = classnames(styles.radioButton, {
    [styles.radioButtonSelected]: selected,
    [styles[color]]: true
  });

  return (
    <div className={`${className} ${classes}`} onClick={onClick}>
      {children}
    </div>
  );
};

const RadioButtons = ({ children, className }) => {
  return (
    <div className={`${className} ${styles.radioButtons}`}>{children}</div>
  );
};

export default RadioButton;

export { RadioButtons, RadioButton };

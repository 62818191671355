import React from "react";
import DaySelector from "../components/calendar/DaySelector";
import styles from "./Home.module.css";
import ButtonLink from "../components/ButtonLink";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Reservations from "./Reservations";
import { UserContext } from "../context/user-context";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = React.useContext(UserContext);
  const isPlayerOrAdmin =
    user.type === "player" ||
    user.type === "resource_admin" ||
    user.type === "club_admin" ||
    user.type === "admin";

  React.useEffect(() => {
    if (!isPlayerOrAdmin) {
      navigate("/calendar");
    }
  }, [user, isPlayerOrAdmin, navigate]);

  const [selectedDate, setSelectedDate] = React.useState(
    format(new Date(), "yyyy-MM-dd")
  );

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.h3}>{t("home.book")}</h3>
      <DaySelector selectedDate={selectedDate} onDayChange={setSelectedDate}>
        <ButtonLink secondary block to={`/reservations?date=${selectedDate}`}>
          {t("home.availableSlots")}
        </ButtonLink>
      </DaySelector>
      <Reservations>
        <h3 className={styles.h3}>{t("home.yourReservations")}</h3>
      </Reservations>
    </div>
  );
};

export default Home;

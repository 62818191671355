import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import classnames from "classnames";
import styles from "./AuthenticatedApp.module.css";
import Header from "./components/Header.js";
import Nav from "./components/Nav.js";
import Home from "./screens/Home.js";
import ReservationForm from "./screens/ReservationForm";
import Calendar from "./screens/Calendar";
import MyReservations from "./screens/MyReservations";
import Profile from "./screens/Profile";
import ChangePassword from "./screens/ChangePassword";
import Rules from "./screens/Rules";
import Today from "./screens/Today";
import Admin from "./screens/Admin";

function AuthenticatedApp({ onLogout }) {
  return (
    <div>
      <Routes>
        <Route exact path="/today" element={<Today />} />
        <Route path="/*" element={<DefaultContainer onLogout={onLogout} />} />
      </Routes>
    </div>
  );
}

function DefaultContainer({ onLogout }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const appClasses = classnames(styles.app, {
    [styles.open]: menuOpen,
  });
  const mainContentClasses = classnames(styles.mainContent, {
    [styles.open]: menuOpen,
  });

  return (
    <div className={appClasses}>
      <Nav isOpen={menuOpen} onItemClick={() => setMenuOpen(false)} />
      <div className={mainContentClasses}>
        <Header toggleMenu={() => setMenuOpen(!menuOpen)} />
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/reservations" element={<ReservationForm />}>
            <Route
              path="/reservations/:reservationId"
              element={<ReservationForm />}
            />
          </Route>
          <Route path="/calendar" element={<Calendar />}>
            <Route path=":date" element={<Calendar />} />
            <Route path=":date/:reservationId" element={<Calendar />} />
          </Route>
          <Route path="/my_reservations" element={<MyReservations />} />
          <Route path="/my_profile" element={<Profile onLogout={onLogout} />} />
          <Route path="/change_password" element={<ChangePassword />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/admin" element={<Admin />}>
            <Route path="/admin/events" element={<Admin />} />
            {/* <Route path="/admin/users" element={<Admin />} />
            <Route path="/admin/recurring_reservations" element={<Admin />} />
            <Route path="/admin/schedule" element={<Admin />} /> */}
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default AuthenticatedApp;

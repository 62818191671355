import React from "react";
import styles from "./Dropdown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

// options: [ { value: 1, label: "One" } ]
// active is just the value

const Dropdown = ({ options, onChange, active, icon = null, className }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef();
  const activeOption = options.find((option) => option.value === active);

  React.useEffect(() => {
    document.body.addEventListener("click", (ev) => {
      if (!ref.current || ref.current.contains(ev.target)) {
        return;
      }

      setIsOpen(false);
    });
  }, []);

  return (
    <div className={`${styles.wrapper} ${className}`} ref={ref}>
      <div className={styles.dropDown} onClick={() => setIsOpen(!isOpen)}>
        {icon && (
          <FontAwesomeIcon icon={icon} className={styles.dropDownIcon} />
        )}
        <div className={styles.dropDownValue}>{activeOption.label}</div>
        <FontAwesomeIcon
          icon={faChevronDown}
          size="sm"
          className={styles.dropDownChevron}
        />
      </div>
      {isOpen && (
        <ul className={styles.list}>
          {options.map((option) => (
            <li
              className={`${styles.listItem} ${
                option.value === active && styles.selected
              }`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
              key={option.value}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

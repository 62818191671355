import React from "react";
import styles from "./Player.module.css";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const Player = ({
  name,
  details,
  onRemove,
  playerId,
  status,
  reservationMemberId,
}) => {
  const showRemove = () => {
    if (onRemove) {
      return (
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.removeIcon}
          onClick={() => onRemove(playerId)}
        />
      );
    }
  };

  const showDetails = () => {
    if (details) {
      return <div className={styles.details}>{details}</div>;
    }
  };

  const showStatus = () => {
    switch (status) {
      case "accepted":
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={styles.acceptedIcon}
          />
        );
      case "rejected":
        return (
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={styles.rejectedIcon}
          />
        );
      default:
        return;
    }
  };

  return (
    <li className={styles.player}>
      <Avatar className={styles.playerAvatar} initial={name.charAt(0)} />
      <div className={styles.playerName}>
        <span className={`${status === "rejected" && styles.playerRejected}`}>
          {name}
        </span>
        {showStatus()}
        {showDetails()}
      </div>
      {/* {showInviteAgain()} */}
      {showRemove()}
    </li>
  );
};

export default Player;

import React from "react";
import Button from "../components/Button";
import Alert from "../components/Alert";
import styles from "./PasswordConfirmation.module.css";
import { useTranslation } from "react-i18next";
import PasswordField from "../components/PasswordField";

const PasswordConfirmation = ({
  password,
  setPassword,
  errors,
  setErrors,
  onSubmit,
  submitLabel
}) => {
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const { t } = useTranslation();

  const passwordValid = () => {
    let valid = true;
    let errors = [];
    setErrors([]);
    if (password.length < 6) {
      valid = false;
      errors.push(t("changePassword.passwordIsTooShort"));
    }
    if (password !== passwordConfirmation) {
      valid = false;
      errors.push(t("changePassword.passwordDoesntMatch"));
      setPasswordConfirmation("");
    }
    setErrors(errors);
    return valid;
  };

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        if (passwordValid()) {
          onSubmit();
        }
      }}
    >
      <label className={styles.label}>{t("changePassword.password")}</label>
      <PasswordField value={password} setValue={setPassword} name="password" />
      <label className={styles.label}>
        {t("changePassword.passwordConfirmation")}
      </label>
      <PasswordField
        value={passwordConfirmation}
        setValue={setPasswordConfirmation}
        name="passwordConfirmation"
      />
      {errors.length > 0 && (
        <Alert>
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </Alert>
      )}
      <Button block type="submit" className={styles.submitButton}>
        {submitLabel}
      </Button>
    </form>
  );
};

export default PasswordConfirmation;

import React from "react";
import styles from "./Tags.module.css";
import classNames from "classnames";

const Tags = ({ children }) => {
  return <ul className={styles.tags}>{children}</ul>;
};

const Tag = ({ children, solid, color }) => {
  const classes = classNames(styles.tag, {
    [styles.solid]: solid,
    [styles.color2]: color === 2,
    [styles.color3]: color === 3,
    [styles.color4]: color === 4
  });

  return <li className={classes}>{children}</li>;
};

export { Tags, Tag };

import { Oval } from "react-loader-spinner";
import styles from "./Loader.module.css";

const Loader = ({ width = 60, height = 60, className }) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Oval
        width={width}
        height={height}
        color={styles.primaryColor}
        secondaryColor={styles.primaryLight}
      />
    </div>
  );
};

export default Loader;

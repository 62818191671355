import {
  faChevronLeft,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styles from "./ChangePassword.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkButton from "../components/LinkButton";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";
import { client, clientUnauth } from "../utils/api-client";
import { useSearchParams } from "react-router-dom";
import PasswordConfirmation from "./PasswordConfirmation";

const ChangePassword = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [passwordReset, setPasswordReset] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  let [searchParams] = useSearchParams();

  const handleFormSubmit = () => {
    setIsLoading(true);
    if (userContext) {
      client("auth/password.json", {
        method: "PUT",
        body: JSON.stringify({
          password: password,
          password_confirmation: password
        })
      })
        .then(() => {
          setIsLoading(false);
          setPasswordReset(true);
        })
        .catch((response) => {
          setErrors([response.errors.password]);
          setIsLoading(false);
        });
    } else {
      //submit passwordReset call
      clientUnauth("auth/reset_password_token.json", {
        method: "POST",
        body: JSON.stringify({
          password,
          reset_password_token: searchParams.get("reset_password_token")
        })
      }).then(() => {
        setIsLoading(false);
        setPasswordReset(true);
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      {userContext?.user && (
        <Link to="/my_profile">
          <LinkButton>
            <FontAwesomeIcon icon={faChevronLeft} className={styles.backIcon} />
            {t("changePassword.backToProfile")}
          </LinkButton>
        </Link>
      )}
      <h3 className={styles.h3}>{t("changePassword.resetPassword")}</h3>
      <p className={styles.description}>{t("changePassword.description")}</p>
      {isLoading ? (
        <Loader />
      ) : passwordReset ? (
        <div className={styles.successMessage}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="lg"
            className={styles.successIcon}
          />
          {t("changePassword.successMessage")}
        </div>
      ) : (
        <PasswordConfirmation
          password={password}
          setPassword={setPassword}
          errors={errors}
          setErrors={setErrors}
          onSubmit={handleFormSubmit}
          submitLabel={t("changePassword.updatePassword")}
        />
      )}
      {!userContext && (
        <Link to="/" className={styles.loginLink}>
          <LinkButton>{t("changePassword.backToLogin")}</LinkButton>
        </Link>
      )}
    </div>
  );
};

export default ChangePassword;

import { addDays, subDays, format, getWeek } from "date-fns";
import i18next from "i18next";
import * as locales from "date-fns/locale";

const currentLocale = locales[i18next.language];

function getWeekDayFromMonday(weekDay) {
  weekDay = parseInt(weekDay);
  return weekDay === 0 ? 7 : weekDay;
}

function getMonday(date) {
  const weekDay = getWeekDayFromMonday(date.getDay());
  return subDays(date, weekDay - 1);
}

function getActiveWeek(date, activeDays) {
  const firstDay = getMonday(date);
  let days = [];
  let usedActiveDays = 0;
  let week = {
    id: getWeek(date, { weekStartsOn: 1 }),
    month: format(firstDay, "MMMM yyyy", { locale: currentLocale })
  };
  let lastDay;

  for (let i = 0; i < 7; i++) {
    let day = addDays(firstDay, i);
    let disabled = true;
    lastDay = day;

    if (day >= date && usedActiveDays < activeDays) {
      usedActiveDays++;
      disabled = false;
    }

    days.push({
      weekDay: format(day, "EEEEE", { locale: currentLocale }),
      day: format(day, "d", { locale: currentLocale }),
      date: format(day, "yyyy-MM-dd", { locale: currentLocale }),
      disabled
    });
  }

  week.days = days;

  return { week, remainingActiveDays: activeDays - usedActiveDays, lastDay };
}

function getActiveWeeks(date = new Date(), activeDays = 8) {
  let weeks = [];
  let { week, remainingActiveDays, lastDay } = getActiveWeek(date, activeDays);
  weeks.push(week);

  if (remainingActiveDays > 0) {
    let { week } = getActiveWeek(addDays(lastDay, 1), remainingActiveDays);
    weeks.push(week);
  }

  return weeks;
}

function getActiveWeekIndex(availableWeeks, selectedDate) {
  let activeWeek = 0;

  availableWeeks.forEach((week, weekIndex) => {
    week.days.forEach((day) => {
      if (day.date === selectedDate) {
        activeWeek = weekIndex;
      }
    });
  });

  return activeWeek;
}

function getActiveDays(activeDays = 8) {
  const days = [];
  const firstDay = new Date();
  for (let i = 0; i < activeDays; i++) {
    let day = addDays(firstDay, i);
    days.push({
      fullDate: day,
      date: format(day, "yyyy-MM-dd", { locale: currentLocale })
    });
  }

  return days;
}

export { getActiveWeeks, getActiveWeekIndex, getActiveDays };

import React, { useState } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import styles from "./ReservationForm.module.css";
import { format, getDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { client } from "../utils/api-client";
import { useNavigate } from "react-router-dom";
import ReservationTeeTimes from "./ReservationForm/ReservationTeeTimes";
import ReservationPlayers from "./ReservationForm/ReservationPlayers";
import ReservationConfirmation from "./ReservationForm/ReservationConfirmation";
import CountdownTimer from "../components/CountdownTimer";

const ReservationForm = () => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const reservationId = searchParams.get("id");
  const date = searchParams.get("date")
    ? new Date(`${searchParams.get("date")}T00:00:00`)
    : new Date();
  const [reservationFetched, setReservationFetched] = useState(false);
  const [resourcesFetched, setResourcesFetched] = useState(false);
  const [resources, setResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedDate, setSelectedDate] = useState(format(date, "yyyy-MM-dd"));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [players, setPlayers] = useState([]);
  const [guests, setGuests] = useState([]);
  const [recurringReservationId, setRecurringReservationId] = useState(null);
  const [activeReservation, setActiveReservation] = useState(null);
  const [reservationExpiry, setReservationExpiry] = useState(null);
  let navigate = useNavigate();

  React.useEffect(() => {
    if (searchParams.get("recurringId")) {
      setRecurringReservationId(searchParams.get("recurringId"));
    }
  }, [recurringReservationId, searchParams]);

  React.useEffect(() => {
    client("resources.json").then((responseData) => {
      setResourcesFetched(true);
      setResources(responseData);
    });
  }, [resourcesFetched]);

  React.useEffect(() => {
    if (reservationFetched) return;
    if (!reservationId || reservationId === "null") {
      setReservationFetched(true);
      return;
    }

    client(`reservations/${reservationId}.json`).then((responseData) => {
      setReservationFetched(true);
      const reservation = responseData;
      setSelectedDate(reservation.date);
      setSelectedTimeSlot(reservation.time_slot);
      setSelectedResource(reservation.resource.id);
      setPlayers(reservation.users);
      setGuests(reservation.guests);
    });
  }, [reservationFetched, reservationId]);

  React.useEffect(() => {
    if (activeReservation) {
      setReservationExpiry(activeReservation.expiry);
    }
  }, [activeReservation]);

  function getTeeTimeDetails() {
    const resourceName = resources.find(
      (resource) => resource.id === selectedResource
    )?.name;

    return `${t("reservationForm.fullDate", {
      date: new Date(`${selectedDate}T00:00:00`),
    })}, ${selectedTimeSlot}, ${resourceName}`;
  }

  function isFriday() {
    return 5 === getDay(new Date(`${selectedDate}T00:00`));
  }

  const onPartiallyBookedExpired = () => {
    setActiveReservation(null);
    navigate("/reservations");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.h3}>{t("reservationForm.bookTeeTime")}</h3>
        {activeReservation && reservationExpiry && (
          <CountdownTimer
            className={styles.timer}
            targetDate={reservationExpiry}
            onExpiry={onPartiallyBookedExpired}
          />
        )}
      </div>
      <div className={styles.mainContent}>
        <Routes>
          <Route
            path="/"
            element={
              <ReservationTeeTimes
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedResource={selectedResource}
                setSelectedResource={setSelectedResource}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                activeReservation={activeReservation}
                setActiveReservation={setActiveReservation}
              />
            }
          />
          <Route
            path="/players"
            element={
              <ReservationPlayers
                players={players}
                setPlayers={setPlayers}
                guests={guests}
                setGuests={setGuests}
                teeTimeDetails={getTeeTimeDetails()}
                loading={!reservationFetched}
                recurringReservationId={recurringReservationId}
                isFriday={isFriday()}
              />
            }
          />
          <Route
            path="/confirmation"
            element={
              <ReservationConfirmation
                selectedDate={selectedDate}
                selectedResource={selectedResource}
                selectedTimeSlot={selectedTimeSlot}
                players={players}
                guests={guests}
                resources={resources}
                recurringReservationId={recurringReservationId}
                activeReservation={activeReservation}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default ReservationForm;

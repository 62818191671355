import { getUserAuth } from "./auth-provider";
const apiURL = process.env.REACT_APP_API_URL;

function client(endpoint, customConfig = {}) {
  const userAuth = getUserAuth();
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...userAuth
    },
    ...customConfig
  };

  return fetch(`${apiURL}/${endpoint}`, config).then(async (response) => {
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

function clientUnauth(endpoint, customConfig = {}) {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    ...customConfig
  };

  return fetch(`${apiURL}/${endpoint}`, config);
}

export { client, clientUnauth };

import React from "react";
import styles from "./Admin.module.css";
import { useLocation } from "react-router-dom";
import { TabsNav, TabNav } from "../components/TabsNav";
import Events from "./Admin/Events";

const Admin = () => {
  const defaultRoute = "/admin/events";
  const location = useLocation();
  const activeRoute = location.pathname;

  function getTabContent() {
    switch (activeRoute) {
      case "/admin/users":
        return <div>Users</div>;
      case "/admin/recurring_reservations":
        return <div>Recurring Reservations</div>;
      case "/admin/schedule":
        return <div>Schedule</div>;
      case "/admin/events":
      default:
        return <Events />;
    }
  }

  return (
    <div className={styles.wrapper}>
      {/* <TabsNav className={styles.tabs}>
        <TabNav to="/admin/users" defaultRoute={defaultRoute}>
          Usuarios
        </TabNav>
        <TabNav to="/admin/schedule" defaultRoute={defaultRoute}>
          Horarios
        </TabNav>
        <TabNav to="/admin/events" defaultRoute={defaultRoute}>
          Eventos
        </TabNav>
      </TabsNav> */}
      {getTabContent()}
    </div>
  );
};

export default Admin;

import React from "react";
import styles from "./Button.module.css";
const classnames = require("classnames");

const Button = ({
  children,
  onClick = () => {},
  secondary,
  color = "primary",
  block,
  className,
  disabled = false,
  type = "button"
}) => {
  const buttonStyles = classnames(styles.button, {
    [styles.primary]: !secondary,
    [styles.secondary]: secondary,
    [styles.block]: block,
    [styles.disabled]: disabled,
    [styles.colorSecondary]: color === "secondary"
  });
  return (
    <button
      onClick={onClick}
      className={`${className} ${buttonStyles}`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;

import React from "react";
import styles from "./ReservationComments.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button";
import { client } from "../../utils/api-client";

const ReservationComments = ({
  comments,
  onCommentSubmit,
  onCommentDelete
}) => {
  const [comment, setComment] = React.useState("");
  const handleSubmit = () => {
    onCommentSubmit(comment);
    setComment("");
  };
  const { t } = useTranslation();
  return (
    <>
      <h4 className={styles.h4}>{t("calendar.comments")}</h4>
      {comments?.map((comment) => (
        <Comment
          body={comment.body}
          createdBy={comment.account?.full_name}
          createdAt={comment.created_at}
          id={comment.id}
          key={comment.id}
          onDelete={onCommentDelete}
        />
      ))}
      <textarea
        className={styles.textarea}
        value={comment}
        onChange={(ev) => setComment(ev.target.value)}
      ></textarea>
      <Button secondary onClick={handleSubmit} className={styles.submitButton}>
        {t("calendar.addComment")}
      </Button>
    </>
  );
};

const Comment = ({ id, body, createdBy, createdAt, onDelete }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    client(`comments/${id}.json`, { method: "DELETE" }).catch(() => {});
    onDelete(id);
  };
  return (
    <div className={styles.comment}>
      <div className={styles.commentHeader}>
        <div className={styles.commentAuthor}>{createdBy}</div>
        <div className={styles.commentTime}>
          {t("calendar.shortDateWithTime", { date: new Date(createdAt) })}
        </div>
        <FontAwesomeIcon
          icon={faTrash}
          size="sm"
          onClick={handleDelete}
          className={styles.deleteIcon}
        />
      </div>
      <div className={styles.commentBody}>{body}</div>
    </div>
  );
};

export default ReservationComments;

import React, { useState } from "react";
import AuthenticatedApp from "./AuthenticatedApp";
import Loader from "./components/Loader";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { login, logout, getUser, confirm } from "./utils/auth-provider";
import { UserContext } from "./context/user-context";
import { useNavigate } from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);
  const [userFetched, setUserFetched] = useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    if (userFetched) return;
    getUser()
      .then((response) => {
        setUser(response.data);
        setUserFetched(true);
      })
      .catch((err) => {
        console.error(err);
        setUserFetched(true);
      });
  }, [userFetched]);

  const onLogin = (form) =>
    login(form).then((response) => setUser(response.data));
  const onLogout = () => {
    logout();
    setUser(null);
    navigate("/");
  };
  const onConfirm = (form) =>
    confirm(form).then((response) => {
      setUser(response.data);
      navigate("/");
    });

  return !userFetched ? (
    <Loader />
  ) : user ? (
    <UserContext.Provider value={{ user, setUser }}>
      <AuthenticatedApp onLogout={onLogout} />
    </UserContext.Provider>
  ) : (
    <UnauthenticatedApp onLogin={onLogin} onConfirm={onConfirm} />
  );
}

export default App;

import React from "react";
import classnames from "classnames";
import styles from "./CountdownTimer.module.css";

const CountdownTimer = ({ targetDate, onExpiry, className }) => {
  const [minutes, seconds] = useCountdown(targetDate);
  const classes = classnames(className, styles.timer, {
    [styles.danger]: minutes < 1
  });

  if (minutes + seconds <= 0) {
    onExpiry();
  }

  return <div className={classes}>{`${minutes}:${seconds}`}</div>;
};

const useCountdown = (targetDate) => {
  // https://blog.greenroots.info/how-to-create-a-countdown-timer-using-react-hooks
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = React.useState(
    countDownDate - new Date().getTime()
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return [minutes, seconds];
};

export default CountdownTimer;

import React from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Avatar from "./Avatar.js";
import { UserContext } from "../context/user-context";

const Header = ({ toggleMenu }) => {
  const { user } = React.useContext(UserContext);
  const initial = user.display_name.charAt(0);

  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        <FontAwesomeIcon
          className={styles.menuIcon}
          icon={faBars}
          size="lg"
          onClick={toggleMenu}
        />
      </div>
      <div className={styles.rightContainer}>
        <Link to="/my_profile">
          <Avatar initial={initial} />
        </Link>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";
import { UserContext } from "../../context/user-context";
import styles from "../ReservationForm.module.css";
import LinkButton from "../../components/LinkButton";
import PlayerSelector from "../../components/PlayerSelector";
import Player from "../../components/Player";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

const ReservationPlayers = ({
  players,
  setPlayers,
  guests,
  setGuests,
  teeTimeDetails,
  loading,
  recurringReservationId,
  isFriday,
}) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const reservationId = searchParams.get("id");
  const playerLimit = 5;
  const playerCount = players.length + guests.length;
  const { user } = React.useContext(UserContext);
  const [currentUserSet, setCurrentUserSet] = React.useState(false);

  React.useEffect(() => {
    if (currentUserSet) return;
    if (players.length === 0) {
      addPlayer({
        account_id: user.account_id,
        membership: user.membership,
        full_name: `${user.display_name}`,
      });
    }
    setCurrentUserSet(true);
  }, [
    currentUserSet,
    players.length,
    user.account_id,
    user.display_name,
    user.membership,
  ]);

  function addGuest(guest) {
    setGuests([...guests, { id: uuid(), full_name: guest }]);
  }

  function addPlayer(player) {
    setPlayers([...players, player]);
  }

  function removePlayer(playerId) {
    const updatedPlayers = players.filter(
      (player) => player.account_id !== playerId
    );
    setPlayers(updatedPlayers);
  }

  function removeGuest(guestId) {
    const updatedGuests = guests.filter((guest) => guest.id !== guestId);
    setGuests(updatedGuests);
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <p className={styles.p}>
          {t("reservationForm.addPlayers", { details: teeTimeDetails })}
        </p>
        {!recurringReservationId && (
          <Link to={`/reservations?id=${reservationId}`}>
            <LinkButton>{t("reservationForm.changeDate")}</LinkButton>
          </Link>
        )}
      </div>
      {playerCount < playerLimit ? (
        <PlayerSelector
          addPlayer={addPlayer}
          addGuest={addGuest}
          selectedPlayers={players}
        />
      ) : (
        <Alert>{t("reservationForm.playerLimitWarning")}</Alert>
      )}

      <ul className={styles.players}>
        {players.map((player) => (
          <Player
            key={player.account_id}
            name={player.full_name}
            details={t("player.member", { member: player.membership })}
            onRemove={() => removePlayer(player.account_id)}
            playerId={player.id}
            status={player.status || "invited"}
            reservationMemberId={player.reservation_member_id || null}
          />
        ))}
        {guests.map((guest) => (
          <Player
            key={guest.id}
            name={guest.full_name}
            onRemove={() => removeGuest(guest.id)}
            details={t("reservationForm.guest")}
          />
        ))}
      </ul>
      {isFriday && (
        <Alert>
          <b>{t("reservationForm.rulesForGuests.title")}</b>
          <ul className={styles.rules}>
            <li>{t("reservationForm.rulesForGuests.rule1")}</li>
            <li>{t("reservationForm.rulesForGuests.rule2")}</li>
          </ul>
        </Alert>
      )}

      <Link to={`/reservations/confirmation?id=${reservationId}`}>
        <Button block disabled={playerCount === 0}>
          {t("reservationForm.continue")}
        </Button>
      </Link>
    </div>
  );
};

export default ReservationPlayers;

import React from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { client } from "../../utils/api-client";
import styles from "./Events.module.css";

import Dropdown from "../../components/Dropdown";
import DaySelectorDatePicker from "../../components/calendar/DaySelectorDatePicker";
import Button from "../../components/Button";

const NewEventsModal = ({ closeModal, refreshEvents }) => {
  const { t } = useTranslation();
  const today = format(new Date(), "yyyy-MM-dd");
  const [eventName, setEventName] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [startDate, setStartDate] = React.useState(today);
  const [startTimeHours, setStartTimeHours] = React.useState(6);
  const [startTimeMinutes, setStartTimeMinutes] = React.useState(50);
  const [endDate, setEndDate] = React.useState(today);
  const [endTimeHours, setEndTimeHours] = React.useState(15);
  const [endTimeMinutes, setEndTimeMinutes] = React.useState(10);
  const [resourceId, setResourceId] = React.useState(1);

  // TODO: Fetch resources from API
  const resourceOptions = [
    { value: 1, label: "Hoyo 1" },
    { value: 2, label: "Hoyo 10" },
  ];

  const handleCreateEvent = () => {
    const event = {
      name: eventName,
      description: eventDescription,
      resource_id: resourceId,
      start_date: `${startDate} ${startTimeHours}:${startTimeMinutes}`,
      end_date: `${endDate} ${endTimeHours}:${endTimeMinutes}`,
    };

    client("events.json", {
      method: "POST",
      body: JSON.stringify({
        event: event,
      }),
    }).then(() => {
      closeCreateEventModal();
      refreshEvents(true);
    });
  };

  const resetForm = () => {
    setEventName("");
    setEventDescription("");
    setStartDate(today);
    setEndDate(today);
    setResourceId(1);
  };

  const closeCreateEventModal = () => {
    resetForm();
    closeModal();
  };
  return (
    <div>
      <h3 className={styles.h3}>{t("admin.events.createEvent")}</h3>
      <form onSubmit={handleCreateEvent}>
        <div className={styles.inputGroup}>
          <label htmlFor="name" className={styles.label}>
            {t("admin.events.eventName")}
          </label>
          <input
            id="name"
            className={styles.input}
            type="text"
            name="name"
            value={eventName}
            onChange={(ev) => setEventName(ev.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="description" className={styles.label}>
            {t("admin.events.eventDescription")}
          </label>
          <textarea
            id="description"
            className={styles.textarea}
            name="description"
            value={eventDescription}
            onChange={(ev) => setEventDescription(ev.target.value)}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="resourceId" className={styles.label}>
            {t("admin.events.round")}
          </label>
          <Dropdown
            options={resourceOptions}
            onChange={setResourceId}
            active={resourceId}
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="resourceId" className={styles.label}>
            {t("admin.events.startDate")}
          </label>
          <div className={styles.dateWithTime}>
            <DaySelectorDatePicker
              onChange={setStartDate}
              selectedDate={startDate}
            />
            <div className={styles.timeSelector}>
              <input
                type="number"
                min="0"
                max="24"
                className={styles.inputInline}
                value={startTimeHours}
                onChange={(ev) => setStartTimeHours(ev.target.value)}
              />
              :
              <input
                type="number"
                min="0"
                max="59"
                className={styles.inputInline}
                value={startTimeMinutes}
                onChange={(ev) => setStartTimeMinutes(ev.target.value)}
              />
              hrs
            </div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="resourceId" className={styles.label}>
            {t("admin.events.endDate")}
          </label>
          <div className={styles.dateWithTime}>
            <DaySelectorDatePicker
              onChange={setEndDate}
              selectedDate={endDate}
            />
            <div className={styles.timeSelector}>
              <input
                type="number"
                min="0"
                max="24"
                className={styles.inputInline}
                value={endTimeHours}
                onChange={(ev) => setEndTimeHours(ev.target.value)}
              />
              :
              <input
                type="number"
                min="0"
                max="59"
                className={styles.inputInline}
                value={endTimeMinutes}
                onChange={(ev) => setEndTimeMinutes(ev.target.value)}
              />
              hrs
            </div>
          </div>
        </div>
      </form>
      <div className={styles.buttonRow}>
        <Button
          className={styles.button}
          type="button"
          secondary
          onClick={() => closeCreateEventModal()}
        >
          {t("admin.events.cancel")}
        </Button>
        <Button
          className={styles.button}
          type="submit"
          onClick={handleCreateEvent}
        >
          {t("admin.events.createEvent")}
        </Button>
      </div>
    </div>
  );
};

export default NewEventsModal;

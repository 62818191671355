import React from "react";
import styles from "./Rules.module.css";
import GolfCourseAgreement from "../components/GolfCourseAgreement";

const Rules = () => {
  return (
    <div className={styles.wrapper}>
      <GolfCourseAgreement />
    </div>
  );
};

export default Rules;

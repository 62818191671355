import React from "react";
import styles from "./GolfCourseAgreement.module.css";

const GolfCourseAgreement = () => {
  return (
    <div>
      <h3 className={styles.h3}>Reglamento de reservaciones de salidas</h3>
      <h4 className={styles.h4}>Estructura de salidas y reservas</h4>
      <ol>
        <li>
          Todas las salidas estarán estructuradas con intervalos de 9 minutos de
          lunes a domingo, incluyendo días festivos.
        </li>
        <li>
          Cada salida deberá de ser reservada a través esta apliación con los
          nombres completos de un mínimo de 3 jugadores que integran el grupo,
          la hora y el día deseado a jugar, y en los horarios que aparecen
          libres en la aplicación.
        </li>
        <li>
          Los horarios de reservación serán de 6:50 hrs a 17:02 hrs entre semana
          y de 6:50 hrs a 17:02 hrs en fin de semana. Cualquier jugador que
          desee salir a jugar en otro horario deberá de hacer su petición
          directamente con el starter el mismo día.
        </li>
        <li>Las reservaciones se podrán hacer con 7 días de anticipación.</li>
      </ol>
      <h4 className={styles.h4}>Reglamento de salidas en general</h4>
      <ol>
        <li>
          El horario de lunes a viernes es abierto a todos los Socios y sus
          contingentes.
        </li>
        <li>
          Para los fines de semana, el horario es abierto a todos los socios
          mayores de 18 años.
        </li>
        <li>
          Los jugadores nuevos y los invitados, deberán pasar una prueba con el
          Profesional de golf, autorizando su capacidad y ética golfista para
          salir al campo largo.
        </li>
        <li>
          Es obligación del Profesional de Golf elaborar una lista con los
          nombres de las Damas con hándicap igual y/o menor a 20 (Se tomará en
          cuenta el último hándicap registrado en el GHIN), y una lista con los
          nombres de los (las) jóvenes con licencia 3 y 4. Ambas listas deberán
          ser actualizadas mes con mes.
        </li>
        <li>
          Todos los jugadores sin excepción deberán de reservar su salida
          directamente en la aplicación para poder jugar, no lo podrán hacer a
          través de terceros o mails enviados.
        </li>
        <li>
          Los grupos que tienen salidas fijas podrán incorporar damas cuyo
          hándicap sea igual o menor a 20, a cualquier hora los días sábados y
          domingos.
        </li>
        <li>
          Los grupos que tienen salidas fijas podrán incorporar jóvenes entre 13
          y 18 años, que tengan licencia 3. No más de 2 invitaciones por grupo,
          a cualquier hora, de los días sábados y domingos.
        </li>
        <li>
          Los (las) jóvenes que tienen licencia 4, podrán salir en cualquier
          horario, cualquier día, según lo establece el reglamento de licencias
          para jóvenes.
        </li>
        <li>
          Los menores de 13 años y los (las) jóvenes sin licencia 3 o 4, podrán
          salir al campo a partir de la 12:00 pm los sábados y 11:00 am los
          domingos. En caso de existir espacios libres por poca asistencia,
          estará a discreción del director de golf en acuerdo con el Starter en
          turno, dejar salir a grupos en horarios previos a los estipulados.
        </li>
        <li>
          Los días festivos de entre semana tendrán que reservar su salida como
          cualquier otro día normal. (no aplican salidas para miércoles dámas).
        </li>
        <li>
          Los viernes hasta 4 invitados por socio y podrán salir desde la
          apertura del campo y hasta antes de las 11:30 hrs. Después de las
          11:30 hrs no podrá haber invitados.
        </li>
        <li>
          Los días festivos únicamente podrá ingresar un invitado por grupo y
          podrán salir a cualquier hora permitida por el reglamento.
        </li>
        <li>
          Las personas que reserven una salida libre y no cancelen, se les
          enviará un aviso de advertencia, en una segunda ocasión se bloqueara
          su acceso a la aplicación durante 30 días.
        </li>
      </ol>
      <h3 className={styles.h3}>Reglamento de salidas fijas</h3>
      <div>
        Horario continuo de salidas fijas:
        <ul>
          <li>
            <b>Sábado:</b> 6:50am - 9:23am
          </li>
          <li>
            <b>Domingos:</b> 6:50am - 9:23am
          </li>
          <li>
            <b>Miércoles:</b> 7:53am - 9:10am
          </li>
        </ul>
        Estas salidas se darán en intervalos de 9 minutos, formando un total de
        36 salidas fijas por día. 18 salidas por el tee del hoyo 1, y 18 salidas
        por el tee del hoyo 10.
      </div>
      <ol>
        <li>
          Cada salida fija otorgada constara de un numero registrado de
          jugadores no menor a 6.
        </li>
        <li>
          Cada salida fija tendrá un capitán designado y responsable del uso de
          esa salida. Así como 1 o hasta 2 asistentes subcapitanes.
        </li>
        <li>
          Todas las salidas fijas otorgadas serán monitoreadas y revisadas año
          con año, de tal modo que deberá de usarse en un porcentaje no menor
          del 75 % de las veces para poder seguir vigente, de lo contrario la
          salida será liberada permanentemente.
        </li>
        <li>
          Se considera el uso de la salida cuando al menos 3 integrantes
          registrados la usen en su horario otorgado. De no ser así, por
          intercambio de horarios, el comité podrá considerar situaciones
          esporádicas.
        </li>
        <li>
          Los capitanes no tienen que confirmar su salida fija cada fin de
          semana, ya que es un derecho otorgado con confirmación permanente.
          <br />
          <b>
            NOTA: Si uno o varios integrantes juegan en otro horario al
            designado, y la salida se ocupa por otras personas, se considrará
            como falta de uso para los registros del porcentaje anual.
          </b>
        </li>
        <li>
          Para los registros anuales no se consideran: La semana de semana
          santa. Del 1º. de Julio al 15 de agosto. Del 24 de Diciembre al 6 de
          enero.
        </li>
        <li>
          Cada capitán de salida fija tendrá derecho a hacer 3 cambios durante
          el año de alguno de sus integrantes del grupo.
        </li>
        <li>
          Las salidas son estrictamente otorgadas y autorizadas por el consejo
          de administración a propuesta del comité de golf, de acuerdo a lo que
          establece este reglamento, y en ningún caso son transferibles o
          heredadas a terceros.
        </li>
        <li>
          Ningún integrante de salida fija podrá pertenecer a 2 grupos distintos
          al mismo tiempo.
        </li>

        <li>
          <b>Cancelaciones.</b> Las cancelaciones tendrán que hacerse a través
          de esta aplicación a más tardar el viernes anterior al fin de semana a
          jugar, A LAS 11 am, Las cancelaciones para entre semana se tendrán que
          hacer con 24 horas de anticipación. No es válido mandar avisos con
          terceras personas.
        </li>

        <li>
          Una salida fija cancelada quedara automáticamente liberada para poder
          reservarse en el momento por cualquier otro socio.
        </li>

        <li>
          Si un grupo no se presenta a su salida sin haber cancelado perderá
          automáticamente la salida fija del siguiente fin de semana. A la
          tercera falta por este hecho, el comité de golf podrá proponer al
          consejo de administración la rescisión de otorgamiento de esta salida
          fija.
        </li>

        <li>
          Cada 3 meses se les informara a los capitanes de el porcentaje de uso
          de la salida otorgada.
        </li>
      </ol>
      <h4 className={styles.h4}>Otorgamiento de salida fija</h4>
      <p>
        Para el otorgamiento de una salida fija será necesario hacer una
        solicitud con un mínimo de 3 meses de anticipación dirigida al comité de
        golf, y con copia al director de golf, con los siguientes datos:
      </p>
      <ol>
        <li>
          Nombre de los integrantes del grupo (min 6), que ocuparan la salida
          fija.
        </li>
        <li>
          Nombre del capitán responsable de su uso, y 1 o 2 suplentes
          (subcapitan designado).
        </li>
        <li>Antigüedad de cada uno de los integrantes.</li>
        <li>Horario aproximado solicitado.</li>
      </ol>
      <b>Condiciones para el otorgamiento</b>
      <ol>
        <li>
          Cuando menos 2 integrantes deberán de tener 10 años de antigüedad como
          socios del club.
        </li>
        <li>
          Haber participado en ese término en torneos interiores del club o
          interclubes.
        </li>
        <li>
          Tener un mínimo de 2 años jugando regularmente con el grupo
          solicitante.
        </li>
      </ol>
      <p>
        Esta solicitud será analizada al igual que a los integrantes
        pretendientes, su constancia de juego en los últimos 2 años, y que no
        pertenezcan a ningún otro grupo ya registrado con salida fija.
      </p>
      <p>
        En caso de que algún integrante ya este registrado dentro de algún
        grupo, este tendrá que ser dado de baja previamente por el capitán de
        ese grupo para poder pertenecer a otro.
      </p>
      <p>
        <b>Atte. Comité de Golf</b>
      </p>
    </div>
  );
};

export default GolfCourseAgreement;

import React from "react";
import styles from "./MyReservations.module.css";
import Reservations from "./Reservations";
import Tabs from "../components/Tabs";
import { useTranslation } from "react-i18next";

const MyReservations = () => {
  const { t } = useTranslation();
  const [status, setStatus] = React.useState("future");
  const tabs = [
    { id: "future", name: t("myReservations.future") },
    { id: "past", name: t("myReservations.past") }
  ];

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.h3}>{t("myReservations.yourReservations")}</h3>
      <Tabs
        flex
        tabs={tabs}
        selectedTab={status}
        onTabClick={setStatus}
        className={styles.tabs}
      />

      <Reservations
        status={status}
        showRecurring={status === "future"}
        displayActions={status === "future"}
        showEmpty={true}
      />
    </div>
  );
};

export default MyReservations;

import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "../ReservationForm.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { client } from "../../utils/api-client";

import Button from "../../components/Button";
import LinkButton from "../../components/LinkButton";
import PlayersList from "../../components/PlayersList";
import Checkbox from "../../components/Checkbox";
import Modal from "../../components/Modal";
import GolfCourseAgreement from "../../components/GolfCourseAgreement";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";

const ReservationConfirmation = ({
  selectedDate,
  selectedResource,
  selectedTimeSlot,
  players,
  guests,
  resources,
  recurringReservationId,
  activeReservation
}) => {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const reservationId = activeReservation?.id || searchParams.get("id");
  const [termsAgreement, setTermsAgreement] = React.useState(true);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const playerCount = players.length + guests.length;
  const [isLoading, setIsLoading] = React.useState(false);

  function getResourceName() {
    return resources.find((resource) => resource.id === selectedResource)?.name;
  }

  function handleConfirmation() {
    const reservation = {
      resource_id: selectedResource,
      start_date: new Date(`${selectedDate}T${selectedTimeSlot}`).toString(),
      user_ids: players.map((player) => player.account_id),
      guests: guests.map((guest) => guest.full_name),
      status: "booked"
    };

    if (recurringReservationId) {
      reservation["recurring_reservation_id"] = recurringReservationId;
    }

    setIsLoading(true);
    client(`reservations/${reservationId}.json`, {
      method: "PUT",
      body: JSON.stringify({
        reservation: reservation
      })
    })
      .then(function () {
        setIsLoading(false);
        navigate("/");
      })
      .catch(function (err) {
        setIsLoading(false);
        console.error(err);
      });
  }

  return (
    <>
      <div className={styles.description}>
        <p className={styles.p}>{t("reservationForm.confirmDetails")}</p>
        <h3 className={styles.h3}>
          <span className={styles.fullDate}>
            {t("reservationForm.fullDate", {
              date: new Date(`${selectedDate}T00:00:00`)
            })}
          </span>
          <br />
          {selectedTimeSlot} - {getResourceName()}
        </h3>
        {!recurringReservationId && (
          <Link to={`/reservations?id=${reservationId}`}>
            <LinkButton>{t("reservationForm.changeTime")}</LinkButton>
          </Link>
        )}
      </div>
      <div className={styles.card}>
        <PlayersList players={players} guests={guests} hideMembership={true} />
      </div>
      <Link to={`/reservations/players?id=${reservationId}`}>
        <LinkButton>{t("reservationForm.editPlayers")}</LinkButton>
      </Link>
      {playerCount < 4 && (
        <Alert>{t("reservationForm.smallGroupsWarning")}</Alert>
      )}
      <Checkbox
        checked={termsAgreement}
        onClick={setTermsAgreement}
        className={styles.termsCheckbox}
      >
        He leido y acepto el{" "}
        <LinkButton
          onClick={() => setShowTermsModal(true)}
          className={styles.inlineLink}
        >
          reglamento de golf
        </LinkButton>{" "}
        del CCCM.
      </Checkbox>
      {isLoading ? (
        <Loader />
      ) : (
        <Button
          block
          className={styles.button}
          onClick={handleConfirmation}
          disabled={!termsAgreement}
        >
          {t("reservationForm.bookBtn")}
        </Button>
      )}

      <Modal show={showTermsModal} onClose={() => setShowTermsModal(false)}>
        <GolfCourseAgreement />
      </Modal>
    </>
  );
};

export default ReservationConfirmation;

import React from "react";
import styles from "./Register.module.css";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import Alert from "../components/Alert";
import { t } from "i18next";
const apiURL = process.env.REACT_APP_API_URL;

const Register = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState("");
  const [user, setUser] = React.useState();

  const validatePresence = (fieldsObject) => {
    let missingFields = [];
    let valid = true;

    Object.keys(fieldsObject).forEach((field) => {
      if (fieldsObject[field] === "") {
        valid = false;
        missingFields.push(field);
      }
    });

    return valid ? valid : missingFields;
  };

  const resetMissingFields = (form) => {
    Object.keys(form.elements).forEach((field) =>
      form.elements[field].classList.remove(styles.required)
    );
    setError("");
  };

  const markRequiredFields = (form, missingFields) => {
    missingFields.forEach((field) =>
      form.elements[field].classList.add(styles.required)
    );
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    resetMissingFields(ev.target);
    setIsLoading(true);

    const {
      name,
      first_last_name,
      second_last_name,
      email,
      membership,
      day,
      month,
      year,
      ghin,
      hi
    } = ev.target.elements;

    const requiredFieldsPresent = validatePresence({
      name: name.value,
      first_last_name: first_last_name.value,
      second_last_name: second_last_name.value,
      membership: membership.value,
      email: email.value
    });

    if (requiredFieldsPresent === true) {
      const user = {
        first_name: name.value,
        first_last_name: first_last_name.value,
        second_last_name: second_last_name.value,
        email: email.value,
        membership: membership.value,
        birthday: `${year.value}-${month.value}-${day.value}`,
        ghin: ghin.value,
        hi: hi.value
      };

      fetch(`${apiURL}/log_in_drive.json`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          info: [
            name.value,
            first_last_name.value,
            second_last_name.value,
            email.value,
            membership.value,
            `${year.value}-${month.value}-${day.value}`,
            ghin.value,
            hi.value
          ]
        })
      }).then((response) => {
        if (response.ok) {
          setUser(user);
          setSuccess(true);
        }
      });
    } else {
      setIsLoading(false);
      setError(t("register.missingFields"));
      markRequiredFields(ev.target, requiredFieldsPresent);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.h3}>{t("register.title")}</h3>
      {success ? (
        <>
          <div>{t("register.success")}</div>
          <UserCard user={user} />
        </>
      ) : (
        <>
          <p className={styles.description}>{t("register.description")}</p>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <div>
                <label className={styles.requiredLabel}>
                  {t("register.name")}
                </label>
                <input
                  className={styles.input}
                  id="name"
                  type="text"
                  name="name"
                ></input>
              </div>
              <div>
                <label className={styles.requiredLabel}>
                  {t("register.firstLastName")}
                </label>
                <input
                  className={styles.input}
                  id="first_last_name"
                  type="text"
                  name="first_last_name"
                ></input>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <div>
                <label className={styles.requiredLabel}>
                  {t("register.secondLastName")}
                </label>
                <input
                  className={styles.input}
                  id="second_last_name"
                  type="text"
                  name="second_last_name"
                ></input>
              </div>
              <div>
                <label className={styles.requiredLabel}>
                  {t("register.membership")}
                </label>
                <input
                  className={styles.input}
                  id="membership"
                  type="text"
                  name="membership"
                ></input>
              </div>
            </div>
            <label className={styles.requiredLabel}>
              {t("register.email")}
            </label>
            <input
              className={styles.input}
              id="email"
              type="text"
              name="email"
            ></input>
            <label className={styles.label}>{t("register.dateOfBirth")}</label>
            <div className={styles.birthdayField}>
              <input
                className={styles.input}
                id="day"
                type="text"
                name="day"
                placeholder="DD"
              ></input>
              <div className={styles.divider}>/</div>
              <input
                className={styles.input}
                id="month"
                type="text"
                name="month"
                placeholder="MM"
              ></input>
              <div className={styles.divider}>/</div>
              <input
                className={`${styles.input} ${styles.yearInput}`}
                id="year"
                type="text"
                name="year"
                placeholder={t("register.year")}
              ></input>
            </div>
            <div className={styles.inputGroup}>
              <div>
                <label className={styles.label}>{t("register.ghinId")}</label>
                <input
                  className={styles.input}
                  id="ghin"
                  type="text"
                  name="ghin"
                ></input>
              </div>
              <div>
                <label className={styles.label}>
                  {t("register.handicapIndex")}
                </label>
                <input
                  className={styles.input}
                  id="hi"
                  type="text"
                  name="hi"
                ></input>
              </div>
            </div>
            {error !== "" && <Alert>{error}</Alert>}
            {isLoading ? (
              <Loader />
            ) : (
              <Button className={styles.submitButton} type="submit">
                {t("register.requestRegistration")}
              </Button>
            )}
          </form>
        </>
      )}
    </div>
  );
};

const UserCard = ({ user }) => {
  const {
    first_name,
    first_last_name,
    second_last_name,
    email,
    membership,
    birthday,
    ghin,
    hi
  } = user;
  return (
    <div className={styles.card}>
      <div className={styles.field}>
        <label className={styles.textLabel}>{t("register.name")}</label>
        {`${first_name} ${first_last_name} ${second_last_name}`}
      </div>
      <div className={styles.field}>
        <label className={styles.textLabel}>{t("register.membership")}</label>
        {membership}
      </div>
      <div className={styles.field}>
        <label className={styles.textLabel}>{t("register.email")}</label>
        {email}
      </div>
      {birthday !== "--" && (
        <div className={styles.field}>
          <label className={styles.textLabel}>
            {t("register.dateOfBirth")}
          </label>
          {birthday}
        </div>
      )}
      {ghin !== "" && (
        <div className={styles.field}>
          <label className={styles.textLabel}>{t("register.ghinId")}</label>
          {ghin}
        </div>
      )}
      {hi !== "" && (
        <div className={styles.field}>
          <label className={styles.textLabel}>
            {t("register.handicapIndex")}
          </label>
          {hi}
        </div>
      )}
    </div>
  );
};

export default Register;

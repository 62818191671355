import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { client } from "../../utils/api-client";
import styles from "../ReservationForm.module.css";
import DaySelector from "../../components/calendar/DaySelector";
import RadioButton from "../../components/RadioButton";
import ResourceCheckboxes from "../../components/ResourceCheckboxes";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";

const ReservationTeeTimes = ({
  selectedDate,
  setSelectedDate,
  selectedResource,
  setSelectedResource,
  selectedTimeSlot,
  setSelectedTimeSlot,
  activeReservation,
  setActiveReservation,
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [slots, setSlots] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("loading");

  React.useEffect(() => {
    setStatus("loading");
    client(`resources/available_slots.json?from=${selectedDate}`).then(
      (responseData) => {
        setSlots(responseData);
        setStatus("success");
      }
    );
  }, [selectedDate]);

  React.useEffect(() => {
    setFilteredSlots(getFilteredSlots());
  }, [selectedDate, slots, resources]);

  function getFilteredSlots() {
    let filteredSlots = [];

    slots.forEach((resource) => {
      const resourceCheckbox = resources.find((r) => r.id === resource.id);

      if (resourceCheckbox && resourceCheckbox.checked) {
        if (resource.dates[0]?.date === selectedDate) {
          resource.dates[0]?.times.forEach((time) => {
            filteredSlots.push({ resource: resource.id, time });
          });
        }
      }
    });

    filteredSlots.sort(function (a, b) {
      const date = format(new Date(), "yyyy-MM-dd");
      return new Date(`${date}T${a.time}`) - new Date(`${date}T${b.time}`);
    });

    return filteredSlots;
  }

  function partiallyBookTimeSlot(resource, time_slot) {
    if (activeReservation) {
      const sameTeeTime =
        resource === activeReservation.resource &&
        time_slot === activeReservation.time_slot &&
        selectedDate === activeReservation.date;

      if (!sameTeeTime) {
        client(`reservations/${activeReservation.id}.json`, {
          method: "DELETE",
        }).catch((err) => {
          console.error(err);
        });
        setActiveReservation(null);
      }
    }

    client("reservations/partially_book.json", {
      method: "POST",
      body: JSON.stringify({
        resource_id: resource,
        start_date: new Date(`${selectedDate}T${time_slot}`).toString(),
      }),
    })
      .then((reservation) => {
        setActiveReservation({
          id: reservation.id,
          expiry: reservation.expired_at,
          date: reservation.date,
          time_slot: reservation.time_slot,
          resource: reservation.resource.id,
        });

        navigate(`/reservations/players?id=${reservation.id}`);
      })
      .catch((err) => {
        if (err["start_date"]?.includes("must be an available slot")) {
          setError(t("reservationForm.slotNoLongerAvailable"));
        }

        if (
          err["start_date"]?.includes("already booked a reservation that day")
        ) {
          setError(t("reservationForm.alreadyReservedToday"));
        }
        if (err["temporarily_banned"]?.includes("account temporarily banned")) {
          setError(t("reservationForm.blockedUser"));
        }
      });
  }

  function onTimeSelect(resource, time_slot) {
    setSelectedResource(resource);
    setSelectedTimeSlot(time_slot);
    partiallyBookTimeSlot(resource, time_slot);
  }

  function getTeeTimeColor(resource) {
    const resourceIndex = resources.findIndex((r) => resource === r.id);

    if (resourceIndex === -1) return `color1`;

    return `color${resourceIndex + 1}`;
  }

  function renderTeeTimes() {
    if (filteredSlots.length > 0) {
      return (
        <div className={styles.teeTimes}>
          {filteredSlots.map(({ time, resource }) => (
            <RadioButton
              selected={
                resource === selectedResource && time === selectedTimeSlot
              }
              key={`${time}_${resource}`}
              onClick={() => onTimeSelect(resource, time)}
              color={getTeeTimeColor(resource)}
            >
              {time}
            </RadioButton>
          ))}
        </div>
      );
    } else {
      return (
        <div className={styles.empty}>
          {t("reservationForm.noTeeTimesAvailable")}
        </div>
      );
    }
  }

  return (
    <>
      <div className={styles.description}>
        <p>{t("reservationForm.chooseTeeTime")}</p>
      </div>
      <DaySelector
        onDayChange={(date) => {
          setError("");
          setSelectedDate(date);
        }}
        selectedDate={selectedDate}
      />
      <ResourceCheckboxes
        onUpdate={setResources}
        className={styles.resourceCheckboxesWrapper}
      />
      {status === "loading" ? (
        <Loader />
      ) : (
        <>
          {error !== "" && <Alert>{error}</Alert>}
          {renderTeeTimes()}
        </>
      )}
    </>
  );
};

export default ReservationTeeTimes;

import React from "react";
import styles from "./PasswordField.module.css";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PasswordField = ({ value, setValue, name, visible = true }) => {
  const [isVisible, setIsVisible] = React.useState(visible);

  return (
    <div className={styles.passwordFieldWrapper}>
      <input
        className={styles.passwordFieldInput}
        name={name}
        type={isVisible ? "text" : "password"}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        autoComplete="off"
      />
      <div className={styles.passowordFieldVisibility}>
        <FontAwesomeIcon
          icon={isVisible ? faEyeSlash : faEye}
          onClick={() => setIsVisible(!isVisible)}
        />
      </div>
    </div>
  );
};

export default PasswordField;

import React from "react";
import styles from "./ForgotPassword.module.css";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import LinkButton from "../components/LinkButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { clientUnauth } from "../utils/api-client";

const ForgotPassword = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const { t } = useTranslation();

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    clientUnauth("auth/password.json", {
      method: "POST",
      body: JSON.stringify({
        email: email,
        redirect_url: "https://cccm.teetapp.mx/change_password" //update to dynamic
      })
    })
      .then(async (response) => {
        const data = await response.json();
        setIsLoading(false);

        if (response.ok) {
          setEmailSent(true);
        } else {
          if (response.status === 404) {
            setError(
              "El email ingresado no está registrado en TeeTapp. Porfavor intenta nuevamente con un correo diferente"
            );
          }
          return Promise.reject(data);
        }
      })
      .catch(() => {});
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.h3}>{t("forgotPassword.forgotPassword")}</h3>
      <p className={styles.description}>{t("forgotPassword.description")}</p>
      {isLoading ? (
        <Loader />
      ) : emailSent ? (
        <div className={styles.successMessage}>
          <FontAwesomeIcon
            icon={faEnvelopeCircleCheck}
            size="lg"
            className={styles.successIcon}
          />
          {t("forgotPassword.emailSent", { email })}
        </div>
      ) : (
        <form onSubmit={handleFormSubmit}>
          <label className={styles.label}>{t("forgotPassword.email")}</label>
          <input
            className={styles.input}
            name="email"
            type="text"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete="off"
          />
          {error && <Alert>{error}</Alert>}
          <Button block type="submit">
            {t("forgotPassword.resetPassword")}
          </Button>
        </form>
      )}

      <Link to="/" className={styles.loginLink}>
        <LinkButton>{t("forgotPassword.backToLogin")}</LinkButton>
      </Link>
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import styles from "./Welcome.module.css";
import Loader from "../components/Loader";
import LinkButton from "../components/LinkButton";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordConfirmation from "./PasswordConfirmation";

const Welcome = ({ onConfirm }) => {
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const handleFormSubmit = () => {
    setIsLoading(true);
    // call to api to reset password
    onConfirm({
      password: password,
      confirmation_token: searchParams.get("confirmation_token")
    }).catch((err) => {
      if (err.data.status === 405) {
        setIsLoading(false);
        errors.push(t("welcome.error"));
        setErrors(errors);
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.h3}>{t("welcome.title")}</h3>
      <p className={styles.description}>{t("welcome.description")}</p>
      {isLoading ? (
        <Loader />
      ) : (
        <PasswordConfirmation
          password={password}
          setPassword={setPassword}
          errors={errors}
          setErrors={setErrors}
          onSubmit={handleFormSubmit}
          submitLabel={t("welcome.setPassword")}
        />
      )}
      <Link to="/" className={styles.loginLink}>
        <LinkButton>{t("forgotPassword.backToLogin")}</LinkButton>
      </Link>
    </div>
  );
};

export default Welcome;

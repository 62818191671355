import React from "react";
import { Link } from "react-router-dom";
import styles from "./ButtonLink.module.css";
const classnames = require("classnames");

const ButtonLink = ({ children, to, secondary, block, className, onClick }) => {
  const buttonStyles = classnames(styles.button, {
    [styles.primary]: !secondary,
    [styles.secondary]: secondary,
    [styles.block]: block
  });
  return (
    <Link to={to} onClick={onClick} className={`${className} ${buttonStyles}`}>
      {children}
    </Link>
  );
};

export default ButtonLink;

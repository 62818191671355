import React, { useState, useRef } from "react";
import styles from "./PlayerSelector.module.css";
import Button from "./Button";
import Avatar from "./Avatar";
import { useTranslation } from "react-i18next";
import { client } from "../utils/api-client";

const PlayerSelector = ({ addPlayer, addGuest, selectedPlayers }) => {
  const [showList, setShowList] = useState(false);
  const [players, setPlayers] = useState();
  const [playerInput, setPlayerInput] = useState("");
  const [allPlayers, setAllPlayers] = useState([]);
  const [allPlayersFetched, setAllPlayersFetched] = useState(false);
  const ref = useRef();
  const { t } = useTranslation();

  React.useEffect(() => {
    setPlayers(availablePlayers());
  }, [selectedPlayers]);

  React.useEffect(() => {
    document.body.addEventListener("click", (ev) => {
      if (!ref.current || ref.current.contains(ev.target)) {
        return;
      }

      setShowList(false);
    });
  }, []);

  React.useEffect(() => {
    if (allPlayersFetched) return;
    client("users.json?type=player").then((responseData) => {
      setAllPlayers(responseData);
      setAllPlayersFetched(true);
    });
  }, [allPlayersFetched]);

  function availablePlayers() {
    return allPlayers.filter((player) => !selectedPlayers.includes(player));
  }

  function playersList() {
    if (showList && players.length > 0) {
      return (
        <ul className={styles.players}>
          {players.map((player) => (
            <li
              key={player.account_id}
              className={styles.player}
              onClick={() => {
                setShowList(false);
                addPlayer(player);
              }}
            >
              <Avatar
                initial={player.display_name.charAt(0)}
                size="sm"
                className={styles.playerAvatar}
              />
              {player.display_name}
            </li>
          ))}
        </ul>
      );
    }
  }

  function onPlayerInputChange(ev) {
    setPlayerInput(ev.target.value);
  }

  function filterPlayers() {
    let updatedPlayers = [];

    if (playerInput !== "") {
      updatedPlayers = allPlayers.filter(
        (player) =>
          player.display_name
            .toLowerCase()
            .includes(playerInput.toLowerCase()) ||
          player.full_name.toLowerCase().includes(playerInput.toLowerCase())
      );
    } else {
      updatedPlayers = availablePlayers();
    }

    setPlayers(updatedPlayers);
  }

  function handleAddGuest() {
    if (playerInput !== "") {
      addGuest(playerInput);
      setPlayerInput("");
    }
  }

  return (
    <div className={styles.wrapper} ref={ref}>
      <input
        className={styles.input}
        type="text"
        placeholder={t("reservationForm.playerSelectorPlaceholder")}
        onFocus={() => setShowList(true)}
        onKeyUp={filterPlayers}
        value={playerInput}
        onChange={onPlayerInputChange}
      />
      <Button onClick={handleAddGuest} secondary>
        {t("reservationForm.addPlayerBtn")}
      </Button>
      {playersList()}
    </div>
  );
};

export default PlayerSelector;

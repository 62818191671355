import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { client } from "../utils/api-client";
import styles from "./Today.module.css";

import Loader from "../components/Loader";
import { Tags, Tag } from "../components/Tags";

const Today = () => {
  const [calendar, setCalendar] = React.useState([]);
  const [status, setStatus] = React.useState("loading");
  const selectedDate = format(new Date(), "yyyy-MM-dd");
  const [refreshData, setRefreshData] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setStatus("loading");
    client(`resources/calendar.json?from=${selectedDate}`).then(
      (responseData) => {
        setCalendar(responseData);
        setStatus("success");
      }
    );
  }, [selectedDate]);

  React.useEffect(() => {
    if (refreshData) {
      client(`resources/calendar.json?from=${selectedDate}`).then(
        (responseData) => {
          setCalendar(responseData);
          setRefreshData(false);
        }
      );
    }
  }, [refreshData]);

  // Scroll page every 5 seconds infinitely
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (window.scrollY >= document.body.scrollHeight - window.innerHeight) {
        window.scrollTo(0, 0);
      } else {
        window.scrollBy(0, 344);
      }
    }, 5000);

    return () => clearInterval(interval);
  });

  // Refresh data every minute
  React.useEffect(() => {
    const interval = setInterval(() => {
      setRefreshData(true);
    }, 60000);

    return () => clearInterval(interval);
  });

  return (
    <div className={styles.wrapper}>
      {status === "loading" ? (
        <Loader />
      ) : calendar.length === 0 ? (
        <div className={styles.empty}>{t("calendar.noReservations")}</div>
      ) : (
        <div className={styles.mainContent}>
          <div className={styles.header}>
            {t("reservation.fullDate", {
              date: new Date(`${selectedDate}T00:00:00`),
            })}
          </div>
          <div className={styles.teeTimes}>
            {calendar.map((teeTime, index) => (
              <TeeTime
                teeTime={teeTime}
                key={`${teeTime.time_slot}_${index}`}
                selectedDate={selectedDate}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TeeTime = ({ teeTime, selectedDate, isSelected }) => {
  const { t } = useTranslation();
  const { time, resource, reservation, status, recurring } = teeTime;
  const teeTimeClasses = classNames(styles.teeTime, {
    [styles.teeTimeSelected]: isSelected,
  });

  return (
    <div className={teeTimeClasses}>
      <div className={styles.timeSlot}>{time}</div>
      {status === "available" && !recurring ? (
        <div className={styles.availableSlot}>
          <div className={styles.availableSlotText}>
            {t("calendar.available")}
          </div>
          <Tags>
            <Tag color={resource.id === 1 ? 1 : 2}>{resource?.name}</Tag>
          </Tags>
        </div>
      ) : status === "partially_booked" ? (
        <div className={styles.italic}>{t("calendar.partiallyBooked")}</div>
      ) : (
        <Reservation
          reservation={reservation}
          resource={resource}
          recurring={recurring}
          status={status}
        />
      )}
    </div>
  );
};

const Reservation = ({ reservation, recurring, resource, status }) => {
  const { t } = useTranslation();
  const { users, guests } = reservation;
  const playerClasses = classNames(styles.players, {
    [styles.italic]: status === "available",
  });
  const players =
    recurring && status === "available"
      ? users
          .filter(
            (user) => user.scope === "captain" || user.scope === "subcaptain"
          )
          .map((user) => user.display_name)
          .join(", ")
      : users
          .concat(guests)
          .map((user) => user.display_name)
          .join(", ");

  return (
    <div className={styles.card}>
      <div className={playerClasses}>{players}</div>
      <Tags>
        {recurring && (
          <Tag solid color={2}>
            {t("calendar.fixed")}
          </Tag>
        )}
        <Tag color={resource.id === 1 ? 1 : 2}>{resource?.name}</Tag>
      </Tags>
    </div>
  );
};

export default Today;

import classnames from "classnames";
import styles from "./Alert.module.css";

const Alert = ({ children, type = "warning" }) => {
  const alertClasses = classnames(styles.alert, {
    [styles.alertWarning]: type === "warning"
  });

  return <div className={alertClasses}>{children}</div>;
};

export default Alert;

import React from "react";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { client } from "../../utils/api-client";
import styles from "./Events.module.css";

import Button from "../../components/Button";
import { Tags, Tag } from "../../components/Tags";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import LinkButton from "../../components/LinkButton";
import NewEventsModal from "./NewEventsModal";
import Tabs from "../../components/Tabs";

const Events = () => {
  const { t } = useTranslation();
  const [events, setEvents] = React.useState([]);
  const [eventsLoading, setEventsLoading] = React.useState(false);
  const [refreshEvents, setRefreshEvents] = React.useState(false);
  const [showNewEventsModal, setShowNewEventsModal] = React.useState(false);
  const [status, setStatus] = React.useState("future");

  const tabs = [
    { id: "future", name: t("admin.events.future") },
    { id: "past", name: t("admin.events.past") },
  ];

  React.useEffect(() => {
    const sort = status === "future" ? "asc" : "desc";
    setEventsLoading(true);
    client(`events.json?status=${status}&sort=${sort}`).then((responseData) => {
      setEventsLoading(false);
      setRefreshEvents(false);
      setEvents(responseData);
    });
  }, [refreshEvents, status]);

  function deleteEvent(id) {
    client(`events/${id}.json`, {
      method: "DELETE",
    }).catch((err) => {
      console.error(err);
    });
    setRefreshEvents(true);
  }

  return (
    <div className={styles.wrapper}>
      <>
        <div className={styles.header}>
          <h3 className={styles.h3}>{t("admin.events.events")}</h3>
          <Button secondary onClick={() => setShowNewEventsModal(true)}>
            {t("admin.events.createEvent")}
          </Button>
        </div>
        <Tabs tabs={tabs} selectedTab={status} onTabClick={setStatus} flex />
        {eventsLoading ? (
          <Loader />
        ) : events.length ? (
          <div>
            {events.map((event) => (
              <Event event={event} deleteEvent={deleteEvent} key={event.id} />
            ))}
          </div>
        ) : (
          <div className={styles.empty}>
            {status === "future"
              ? t("admin.events.noFutureEvents")
              : t("admin.events.noPastEvents")}
          </div>
        )}
      </>
      <Modal
        show={showNewEventsModal}
        onClose={() => setShowNewEventsModal(false)}
      >
        <NewEventsModal
          closeModal={() => setShowNewEventsModal(false)}
          refreshEvents={setRefreshEvents}
        />
      </Modal>
    </div>
  );
};

const Event = ({ event, deleteEvent }) => {
  const { t } = useTranslation();
  const { id, name, start_date, end_date, resource } = event;
  const [showCancelModal, setShowCancelModal] = React.useState(false);

  function handleDeleteEvent() {
    deleteEvent(id);
    setShowCancelModal(false);
  }

  return (
    <div className={styles.card}>
      <div className={styles.eventInfo}>
        <div className={styles.eventInfoTop}>
          <h4 className={styles.h4}>{name}</h4>
          <Tags>
            <Tag color={resource.id}>{resource.name}</Tag>
          </Tags>
        </div>
        <div className={styles.eventDetails}>
          <div>
            <b>{t("admin.events.startDate")}</b>{" "}
            {t("calendar.fullDateWithTime", { date: new Date(start_date) })}
          </div>
          <div>
            <b>{t("admin.events.endDate")}</b>{" "}
            {t("calendar.fullDateWithTime", { date: new Date(end_date) })}
          </div>
        </div>
      </div>
      <LinkButton onClick={() => setShowCancelModal(true)} secondary>
        <FontAwesomeIcon icon={faTrash} className={styles.eventDelete} />
      </LinkButton>
      <Modal show={showCancelModal} onClose={() => setShowCancelModal(false)}>
        <h3 className={styles.h3}>{t("admin.events.deleteEvent")}</h3>
        <p>{t("admin.events.deleteEventAreYouSure", { name: name })}</p>
        <Button block onClick={handleDeleteEvent}>
          {t("admin.events.deleteEvent")}
        </Button>
      </Modal>
    </div>
  );
};

export default Events;

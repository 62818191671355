import { client } from "./api-client";

const localStorageAuthKey = "__TeeTapp_user_auth__";
const localStorageUserKey = "__TeeTapp_user__";
const apiURL = process.env.REACT_APP_API_URL;

function getUserAuth() {
  return JSON.parse(window.localStorage.getItem(localStorageAuthKey));
}

async function getUser() {
  return await client("auth/validate_token.json");
}

function handleUserResponse(response) {
  window.localStorage.setItem(
    localStorageAuthKey,
    JSON.stringify(response.headers)
  );
  window.localStorage.setItem(
    localStorageUserKey,
    JSON.stringify(response.data)
  );
  return response;
}

async function logout() {
  const userAuth = getUserAuth();

  window.localStorage.removeItem(localStorageAuthKey);
  window.localStorage.removeItem(localStorageUserKey);

  const response = await fetch(`${apiURL}/auth/sign_out.json`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...userAuth
    }
  });
  const data = await response.json();
  if (response.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
}

function login({ email, password }) {
  return fetch(`${apiURL}/auth/sign_in.json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      password
    })
  })
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return {
          headers: {
            "access-token": response.headers.get("access-token"),
            "token-type": response.headers.get("token-type"),
            client: response.headers.get("client"),
            uid: response.headers.get("uid")
          },
          data: data.data
        };
      } else {
        return Promise.reject({ data, response });
      }
    })
    .then((response) => {
      handleUserResponse(response);
      return response;
    });
}

function confirm({ password, confirmation_token }) {
  return fetch(
    `${apiURL}/auth/confirmation.json?password=${password}&confirmation_token=${confirmation_token}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return {
          headers: {
            "access-token": response.headers.get("access-token"),
            "token-type": response.headers.get("token-type"),
            client: response.headers.get("client"),
            uid: response.headers.get("uid")
          },
          data: data.data
        };
      } else {
        return Promise.reject({ data, response });
      }
    })
    .then((response) => {
      handleUserResponse(response);
      return response;
    });
}

export { login, logout, getUserAuth, getUser, confirm };

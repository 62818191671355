import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./screens/Login";
import ChangePassword from "./screens/ChangePassword";
import ForgotPassword from "./screens/ForgotPassword";
import Welcome from "./screens/Welcome";
import Register from "./screens/Register";

const UnauthenticatedApp = ({ onLogin, onConfirm }) => {
  return (
    <Routes>
      <Route path="/*" element={<Login onLogin={onLogin} />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/change_password" element={<ChangePassword />} />
      <Route path="/welcome" element={<Welcome onConfirm={onConfirm} />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};

export default UnauthenticatedApp;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useParams, Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/user-context";

import { client } from "../utils/api-client";
import styles from "./Calendar.module.css";

import DaySelector from "../components/calendar/DaySelector";
import DaySelectorDropdown from "../components/calendar/DaySelectorDropdown";
import DaySelectorDatePicker from "../components/calendar/DaySelectorDatePicker";
import Button from "../components/Button";
import LinkButton from "../components/LinkButton";
import { Tags, Tag } from "../components/Tags";
import Loader from "../components/Loader";
import ReservationDetails from "./Calendar/ReservationDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ResourceCheckboxes from "../components/ResourceCheckboxes";
import Alert from "../components/Alert";

const Calendar = () => {
  const [calendar, setCalendar] = React.useState([]);
  const [resources, setResources] = React.useState([]);
  const [filteredCalendar, setFilteredCalendar] = React.useState([]);
  const [status, setStatus] = React.useState("loading");
  const [error, setError] = React.useState("");
  const params = useParams();
  const [reservationId, setReservationId] = React.useState(
    params.reservationId
  );
  const selectedDate = params.date || format(new Date(), "yyyy-MM-dd");
  const navigate = useNavigate();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;
  const { user } = React.useContext(UserContext);
  const isStarter = user.type === "starter";
  const isResourceAdmin = user.type === "resource_admin";
  const { t } = useTranslation();
  const headerClasses = classNames({
    [styles.desktopHeader]: !isMobile,
  });

  React.useEffect(() => {
    setStatus("loading");
    client(`resources/calendar.json?from=${selectedDate}`).then(
      (responseData) => {
        setCalendar(responseData);
        setStatus("success");
      }
    );
  }, [selectedDate]);

  React.useEffect(() => {
    setReservationId(params.reservationId);
  }, [params]);

  const getFilteredCalendar = () => {
    const checkedResourcesIds = resources
      .filter((resource) => resource.checked)
      .map((resource) => resource.id);
    return calendar.filter((item) =>
      checkedResourcesIds.includes(item.resource.id)
    );
  };

  React.useEffect(() => {
    setFilteredCalendar(getFilteredCalendar());
  }, [calendar, resources]);

  const handleDayChange = (date) => {
    navigate(`/calendar/${date}`);
  };

  if (isMobile && params.reservationId) {
    return (
      <div>
        <div className={styles.wrapper}>
          <Link to={`/calendar/${selectedDate}`}>
            <LinkButton>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={styles.linkIcon}
              ></FontAwesomeIcon>
              Calendario
            </LinkButton>
          </Link>
          <ReservationDetails reservationId={reservationId} />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={headerClasses}>
        {isStarter || isResourceAdmin ? (
          <DaySelectorDatePicker
            onChange={handleDayChange}
            selectedDate={selectedDate}
          />
        ) : isMobile ? (
          <DaySelector
            onDayChange={handleDayChange}
            selectedDate={selectedDate}
          />
        ) : (
          <DaySelectorDropdown
            onOptionChange={handleDayChange}
            selectedDate={selectedDate}
          />
        )}
        <ResourceCheckboxes
          onUpdate={setResources}
          className={styles.resourceCheckboxes}
        />
      </div>

      {error !== "" && <Alert>{error}</Alert>}

      {status === "loading" ? (
        <Loader />
      ) : filteredCalendar.length === 0 ? (
        <div className={styles.empty}>
          {isStarter
            ? t("calendar.noReservations")
            : t("calendar.noUpcommingReservations")}
        </div>
      ) : (
        <div className={styles.mainContent}>
          <div className={styles.teeTimes}>
            {filteredCalendar.map((teeTime, index) => (
              <TeeTime
                teeTime={teeTime}
                key={`${teeTime.time_slot}_${index}`}
                selectedDate={selectedDate}
                isSelected={
                  reservationId === teeTime.reservation?.id.toString()
                }
                setError={setError}
              />
            ))}
          </div>
          {reservationId && !isMobile && (
            <ReservationDetails
              reservationId={reservationId}
              className={styles.reservationDetails}
            />
          )}
        </div>
      )}
    </div>
  );
};

const TeeTime = ({ teeTime, selectedDate, isSelected, setError }) => {
  const { t } = useTranslation();
  const { time, resource, reservation, status, recurring } = teeTime;
  const teeTimeClasses = classNames(styles.teeTime, {
    [styles.teeTimeSelected]: isSelected,
  });
  let navigate = useNavigate();
  const handleNewReservation = () => {
    client("reservations/partially_book.json", {
      method: "POST",
      body: JSON.stringify({
        resource_id: resource.id,
        start_date: new Date(`${selectedDate}T${time}`).toString(),
      }),
    })
      .then((reservation) => {
        navigate(`/reservations/players?id=${reservation.id}`);
      })
      .catch((err) => {
        if (err["start_date"]?.includes("must be an available slot")) {
          setError(t("reservationForm.slotNoLongerAvailable"));
        }

        if (
          err["start_date"]?.includes("already booked a reservation that day")
        ) {
          setError(t("reservationForm.alreadyReservedToday"));
        }
        if (err["temporarily_banned"]?.includes("account temporarily banned")) {
          setError(t("reservationForm.blockedUser"));
        }
      });
  };
  const { user } = React.useContext(UserContext);
  const isPlayerOrAdmin =
    user.type === "player" ||
    user.type === "resource_admin" ||
    user.type === "club_admin" ||
    user.type === "admin";

  return (
    <div className={teeTimeClasses}>
      <div className={styles.timeSlot}>{time}</div>
      {!recurring && status === "available" && isPlayerOrAdmin ? (
        <Button
          onClick={handleNewReservation}
          secondary
          color={resource.id === 2 && "secondary"}
        >
          {t("calendar.bookTeeTime", { resource: resource.name })}
        </Button>
      ) : !recurring && status === "partially_booked" ? (
        <div className={styles.italic}>{t("calendar.partiallyBooked")}</div>
      ) : status === "booked" ? (
        <Link
          className={styles.reservationLink}
          to={`/calendar/${reservation.date}/${reservation.id}`}
        >
          <Reservation
            reservation={reservation}
            resource={resource}
            recurring={recurring}
            status={status}
          />
        </Link>
      ) : recurring ? (
        <Reservation
          reservation={reservation}
          resource={resource}
          recurring={recurring}
          status={status}
        />
      ) : (
        <>
          <Tags>
            <Tag color={resource.id === 1 ? 1 : 2}>{resource?.name}</Tag>
          </Tags>
          {t("calendar.available")}
        </>
      )}
    </div>
  );
};

const Reservation = ({ reservation, recurring, resource, status }) => {
  const { t } = useTranslation();
  const { users, guests } = reservation;
  const playerClasses = classNames(styles.players, {
    [styles.italic]: status === "available",
  });
  const players =
    recurring && status === "available"
      ? users
          .filter(
            (user) => user.scope === "captain" || user.scope === "subcaptain"
          )
          .map((user) => user.display_name)
          .join(", ")
      : users
          .concat(guests)
          .map((user) => user.display_name)
          .join(", ");

  return (
    <div className={styles.card}>
      <div className={playerClasses}>{players}</div>
      <Tags>
        {recurring && (
          <Tag solid color={2}>
            {t("calendar.fixed")}
          </Tag>
        )}
        <Tag color={resource.id === 1 ? 1 : 2}>{resource?.name}</Tag>
      </Tags>
    </div>
  );
};

export default Calendar;

import React from "react";
import styles from "./ReservationDetails.module.css";
import Loader from "../../components/Loader";
import PlayersList from "../../components/PlayersList";
import { client } from "../../utils/api-client";
import { Tag, Tags } from "../../components/Tags";
import { useTranslation } from "react-i18next";
import { RadioButtons, RadioButton } from "../../components/RadioButton";
import { UserContext } from "../../context/user-context";
import ReservationComments from "./ReservationComments";

const ReservationDetails = ({ reservationId, className }) => {
  const [reservation, setReservation] = React.useState(null);
  const [reservationFetched, setReservationFetched] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [comments, setComments] = React.useState(null);
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const isStarter = user.type === "starter";
  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  React.useEffect(() => {
    if (reservationId) {
      setReservationFetched(false);
      client(`reservations/${reservationId}.json`)
        .then((reservation) => {
          setReservation(reservation);
          setReservationFetched(true);
          if (isStarter) {
            setStatus(reservation.report_status);
            setComments(reservation.comments);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [reservationId, isStarter]);

  const handleStatusChange = (status) => {
    setStatus(status);
    client(`reservations/${reservationId}.json`, {
      method: "PUT",
      body: JSON.stringify({
        reservation: { report_status: status },
      }),
    }).then(function (response) {
      setStatus(response.report_status);
    });
  };

  const handleNewComment = (comment) => {
    client(`reservations/${reservationId}/add_comment.json`, {
      method: "POST",
      body: JSON.stringify({
        body: comment,
      }),
    }).then(function (reservation) {
      setComments(reservation.comments);
    });
  };

  const handleCommentDelete = (commentId) => {
    const commentIndex = comments.findIndex(
      (comment) => comment.id === commentId
    );
    if (commentIndex !== -1) {
      comments.splice(commentIndex, 1);
      setComments([...comments]);
    }
  };

  const getReservationStatus = () => {
    if (isStarter) {
      return (
        <>
          <h4 className={styles.h4}>{t("calendar.status")}</h4>
          <RadioButtons>
            <RadioButton
              selected={status === "on_time"}
              onClick={() => handleStatusChange("on_time")}
            >
              {t("calendar.on_time")}
            </RadioButton>
            <RadioButton
              selected={status === "delayed"}
              onClick={() => handleStatusChange("delayed")}
            >
              {t("calendar.delayed")}
            </RadioButton>
            <RadioButton
              selected={status === "no_show"}
              onClick={() => handleStatusChange("no_show")}
            >
              {t("calendar.no_show")}
            </RadioButton>
          </RadioButtons>
        </>
      );
    }
  };

  return (
    <div className={`${className} ${styles.wrapper}`}>
      {!reservationFetched ? (
        <Loader />
      ) : (
        <>
          <div className={styles.header}>
            <h3
              className={styles.h3}
            >{`${reservation.time_slot} - ${reservation.resource.name}`}</h3>
            {reservation.recurring && (
              <Tags clasName={styles.tags}>
                <Tag solid color={2}>
                  {t("calendar.fixed")}
                </Tag>
              </Tags>
            )}
          </div>
          <div>
            {t("reservation.fullDate", {
              date: new Date(`${reservation.date}T00:00:00`),
            })}
          </div>
          {getReservationStatus()}
          <h4 className={styles.h4}>{t("calendar.players")}</h4>
          <div className={isMobile && styles.card}>
            <PlayersList
              players={reservation.users}
              guests={reservation.guests}
              showStatus
              hideMembership
            />
          </div>

          {isStarter && (
            <ReservationComments
              comments={comments}
              onCommentSubmit={handleNewComment}
              onCommentDelete={handleCommentDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReservationDetails;

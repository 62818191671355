import React from "react";
import Loader from "../components/Loader";
import Reservation from "../components/Reservation";
import RecurringReservation from "../components/RecurringReservation";
import { useTranslation } from "react-i18next";
import { client } from "../utils/api-client";
import styles from "./Reservations.module.css";

const Reservations = ({
  status = "future",
  showRecurring = true,
  displayActions = true,
  showEmpty = false,
  children,
}) => {
  const { t } = useTranslation();
  const [reservations, setReservations] = React.useState([]);
  const [reservationsFetched, setReservationsFetched] = React.useState(false);
  const [reservationsLoading, setReservationsLoading] = React.useState(false);
  const [recurringReservations, setRecurringReservations] = React.useState([]);
  const [recurringReservationsFetched, setRecurringReservationsFetched] =
    React.useState(false);
  const [recurringReservationsLoading, setRecurringReservationsLoading] =
    React.useState(false);

  React.useEffect(() => {
    setReservationsLoading(true);
    client(`reservations/own.json?status=${status}`).then((responseData) => {
      setReservationsFetched(true);
      setReservationsLoading(false);
      setReservations(responseData);
    });
  }, [reservationsFetched, status]);

  React.useEffect(() => {
    if (recurringReservationsFetched || !showRecurring) return;
    setRecurringReservationsLoading(true);
    client("recurring_reservations/own.json").then((responseData) => {
      setRecurringReservationsFetched(true);
      setRecurringReservationsLoading(false);
      setRecurringReservations(responseData);
    });
  }, [recurringReservationsFetched, showRecurring]);

  const displayChildren = () => {
    if (
      reservations.length ||
      (recurringReservations.length && showRecurring)
    ) {
      return children;
    }
  };

  if (reservationsLoading || recurringReservationsLoading) return <Loader />;

  return (
    <>
      {displayChildren()}
      <div className={styles.reservations}>
        {recurringReservations.length > 0 &&
          showRecurring &&
          recurringReservations.map((reservation) =>
            reservation.dates.map((date) => (
              <RecurringReservation
                date={date.date}
                time={date.times[0]}
                resource={reservation.resource}
                id={reservation.id}
                key={`${reservation.id}_${date.date}_${date.times[0]}`}
                onUpdate={() => setRecurringReservationsFetched(false)}
              />
            ))
          )}
        {reservations.length > 0 &&
          reservations.map((reservation) => (
            <Reservation
              reservation={reservation}
              key={reservation.id}
              onCancel={() => {
                setReservationsFetched(false);
              }}
              displayActions={displayActions}
            />
          ))}
        {(!reservations.length && !recurringReservations.length) ||
          (!showRecurring && !reservations.length && showEmpty && (
            <div style={{ textAlign: "center" }}>
              {t("myReservations.noReservations")}
            </div>
          ))}
      </div>
    </>
  );
};

export default Reservations;

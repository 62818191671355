import React from "react";
import styles from "./Tabs.module.css";
import classnames from "classnames";

const Tabs = ({ tabs, selectedTab, onTabClick, className, flex }) => {
  const tabsClasses = classnames(styles.tabs, className, {
    [styles.tabsFlex]: flex
  });
  const tabClasses = (id) => {
    return classnames(styles.tab, {
      [styles.tabSelected]: selectedTab === id
    });
  };

  return (
    <ul className={tabsClasses}>
      {tabs.map(({ id, name }) => (
        <li key={id} className={tabClasses(id)} onClick={() => onTabClick(id)}>
          {name}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;

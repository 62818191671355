import React from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "./LinkButton";

const modalRoot = document.getElementById("modal-root");

const Modal = ({ show, onClose, children }) => {
  const modalClasses = classnames(styles.modal, {
    [styles.open]: show
  });

  return ReactDOM.createPortal(
    <div className={modalClasses}>
      <div className={styles.container}>
        <div className={styles.header}>
          <LinkButton onClick={onClose}>
            <FontAwesomeIcon
              icon={faTimes}
              size={"lg"}
              className={styles.closeIcon}
            />
          </LinkButton>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;

import classnames from "classnames";
import React from "react";
import styles from "./Avatar.module.css";

const Avatar = ({ initial, className, size = "md" }) => {
  const avatarClasses = classnames(styles.avatar, {
    [styles.avatarSm]: size === "sm",
    [styles.avatarMd]: size === "md",
    [styles.avatarLg]: size === "lg"
  });

  return <div className={`${className} ${avatarClasses}`}>{initial}</div>;
};

export default Avatar;

import React from "react";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Checkbox.module.css";
import classnames from "classnames";

const Checkbox = ({ checked, onClick, children, color, className }) => {
  const icon = checked ? faCheckSquare : faSquare;
  const checkboxClasses = classnames(styles.checkbox, className);
  const iconClasses = classnames(styles.checkboxIcon, {
    [styles.unchecked]: !checked,
    [styles[color]]: true
  });

  return (
    <div className={checkboxClasses} onClick={() => onClick(!checked)}>
      <FontAwesomeIcon icon={icon} size="lg" className={iconClasses} />
      <label className={styles.checkboxLabel}>{children}</label>
    </div>
  );
};

export default Checkbox;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { client } from "../utils/api-client";
import PlayersList from "./PlayersList";
import styles from "./Reservation.module.css";
import Button from "./Button";
import ButtonLink from "./ButtonLink";
import { Tag, Tags } from "./Tags";
import Modal from "./Modal";
import { UserContext } from "../context/user-context";

const Reservation = ({ reservation, onCancel, displayActions = true }) => {
  const { t } = useTranslation();
  const {
    resource,
    date,
    guests = [],
    users,
    time_slot,
    created_by,
  } = reservation;
  const { user } = React.useContext(UserContext);
  const currentUser = user;
  const [showCancelModal, setShowCancelModal] = useState(false);
  const isOwner = created_by.account_id === currentUser.account_id;
  const userInReservation = users.find(
    (user) => user.account_id === currentUser.account_id
  );
  const userConfirmed = userInReservation?.status === "accepted";

  const handleCancelReservation = () => {
    client(`reservations/${reservation.id}.json`, {
      method: "PUT",
      body: JSON.stringify({
        reservation: { status: "cancelled" },
      }),
    }).then(function () {
      setShowCancelModal(false);
      onCancel();
    });
  };

  const updateUserStatus = (reservationMemberId, status) => {
    client(`reservation_members/${reservationMemberId}.json`, {
      method: "PUT",
      body: JSON.stringify({
        reservation_member: { status: status },
      }),
    }).then(function () {
      setShowCancelModal(false);
      onCancel();
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <div className={styles.flexFill}>
            <h4 className={styles.h4}>
              {t("reservation.fullDate", {
                date: new Date(`${date}T00:00:00`),
              })}
            </h4>
            <h4 className={styles.h4}>
              {time_slot} - {resource.name}
            </h4>
          </div>
          {reservation.recurring && (
            <Tags>
              <Tag solid color={2}>
                {t("reservation.fixed")}
              </Tag>
            </Tags>
          )}
        </div>

        <PlayersList
          players={users}
          guests={guests}
          showStatus
          hideMembership
        />
      </div>
      {displayActions && (
        <div className={styles.buttons}>
          <Button
            secondary
            className={styles.button}
            onClick={() => setShowCancelModal(true)}
          >
            {t("reservation.cancelBtn")}
          </Button>
          {isOwner ? (
            <ButtonLink
              to={`/reservations/players?id=${reservation.id}`}
              secondary
              className={styles.button}
            >
              {t("reservation.edit")}
            </ButtonLink>
          ) : (
            userInReservation &&
            !userConfirmed && (
              <Button
                primary
                className={styles.button}
                onClick={() =>
                  updateUserStatus(
                    userInReservation.reservation_member_id,
                    "accepted"
                  )
                }
              >
                {t("reservation.confirm")}
              </Button>
            )
          )}
        </div>
      )}
      <Modal show={showCancelModal} onClose={() => setShowCancelModal(false)}>
        <h3 className={styles.h3}>{t("reservation.cancelReservationTitle")}</h3>
        {isOwner ? (
          <>
            <p>{t("reservation.cancelReservation")}</p>
            <Button block onClick={() => handleCancelReservation()}>
              {t("reservation.cancelBtn")}
            </Button>
          </>
        ) : (
          <>
            <p>{t("reservation.cancelAttendance")}</p>
            <Button
              block
              onClick={() =>
                updateUserStatus(
                  userInReservation.reservation_member_id,
                  "rejected"
                )
              }
            >
              {t("reservation.cancelAttendanceBtn")}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Reservation;

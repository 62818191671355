import React from "react";
import styles from "./Login.module.css";
import { useNavigate, Link } from "react-router-dom";

import Button from "../components/Button";
import LinkButton from "../components/LinkButton";
import Alert from "../components/Alert";
import PasswordField from "../components/PasswordField";
import { ReactComponent as Logo } from "../images/cccm_logo.svg";
import { useTranslation } from "react-i18next";

const Login = ({ onLogin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogin = (event) => {
    const { email, password } = event.target.elements;
    event.preventDefault();
    onLogin({ email: email.value, password: password.value })
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setErrors([{ type: "warning", message: t("login.invalidPassword") }]);
          setPassword("");
        }
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <form onSubmit={handleLogin}>
        <div className={styles.inputGroup}>
          <label htmlFor="email" className={styles.label}>
            {t("login.email")}
          </label>
          <input
            id="email"
            className={styles.input}
            type="text"
            name="email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <label htmlFor="password" className={styles.label}>
            {t("login.password")}
          </label>
          <PasswordField
            value={password}
            setValue={setPassword}
            name="password"
            visible={false}
          />
        </div>
        <Button type="submit" block>
          {t("login.login")}
        </Button>
        {errors.length !== 0 &&
          errors.map((error) => (
            <Alert type={error.type}>{error.message}</Alert>
          ))}
        <div className={styles.links}>
          <Link to="/forgot_password" className={styles.link}>
            <LinkButton>{t("login.forgotPassword")}</LinkButton>
          </Link>
          <Link to="/register" className={styles.link}>
            <LinkButton>{t("login.register")}</LinkButton>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;

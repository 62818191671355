import React from "react";
import styles from "./Reservation.module.css";
import { client } from "../utils/api-client";
import { Tags, Tag } from "../components/Tags";
import Button from "../components/Button";
import ButtonLink from "../components/ButtonLink";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const RecurringReservation = ({ date, time, resource, id, onUpdate }) => {
  const { t } = useTranslation();
  const [showFreeModal, setShowFreeModal] = React.useState(false);

  const setFree = () => {
    client(`recurring_reservations/${id}/set_free.json`, {
      method: "POST",
      body: JSON.stringify({
        start_date: new Date(`${date}T${time}`).toString(),
      }),
    }).then(function () {
      onUpdate();
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <div className={styles.flexFill}>
            <h4 className={styles.h4}>
              {t("reservation.fullDate", {
                date: new Date(`${date}T00:00:00`),
              })}
            </h4>
            <h4 className={styles.h4}>
              {time} - {resource.name}
            </h4>
          </div>
          <Tags>
            <Tag solid color={2}>
              {t("reservation.fixed")}
            </Tag>
          </Tags>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          secondary
          className={styles.button}
          onClick={() => setShowFreeModal(true)}
        >
          {t("reservation.free")}
        </Button>
        <ButtonLink
          secondary
          className={styles.button}
          to={`/reservations/players?resourceId=${resource.id}&timeSlot=${time}&date=${date}&recurringId=${id}`}
        >
          {t("reservation.edit")}
        </ButtonLink>
      </div>
      <Modal show={showFreeModal} onClose={() => setShowFreeModal(false)}>
        <h3 className={styles.h3}>{t("reservation.freeReservationTitle")}</h3>
        <p>
          {t("reservation.freeReservationDescription", {
            date: `${t("reservation.fullDate", {
              date: new Date(`${date}T00:00:00`),
            })}`,
          })}
        </p>
        <Button block onClick={setFree}>
          {t("reservation.freeReservationBtn")}
        </Button>
      </Modal>
    </div>
  );
};

export default RecurringReservation;

import React from "react";
import styles from "./PlayersList.module.css";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Player = ({ name, description, hideMembership, status }) => {
  return (
    <div className={styles.player}>
      <Avatar
        initial={name.charAt(0)}
        size="sm"
        className={styles.playerAvatar}
      />
      <div
        className={`${styles.playerName} ${
          status === "rejected" && styles.playerRejected
        }`}
      >
        {name}
        {status && status === "accepted" ? (
          <FontAwesomeIcon icon={faCheckCircle} className={styles.check} />
        ) : status === "rejected" ? (
          <FontAwesomeIcon icon={faTimesCircle} className={styles.times} />
        ) : (
          <></>
        )}
      </div>
      {!hideMembership ? (
        <div className={styles.playerDescription}>{description}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

const PlayersList = ({
  players,
  guests,
  showStatus = false,
  hideMembership = false
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.players}>
      {players.map((player) => (
        <Player
          key={player.account_id}
          name={player.full_name}
          description={player.membership}
          hideMembership={hideMembership}
          status={showStatus ? player.status : false}
        />
      ))}
      {guests.map((guest) => (
        <Player
          key={guest.id}
          name={guest.full_name}
          description={t("player.guest")}
        />
      ))}
    </div>
  );
};

export default PlayersList;
